<template>
  <div v-if="selectedItem" class="modalCntnt">
    <div class="row">
      <!-- {{ checkVisibilitySidebar() }} -->
      <div
        :class="'col-lg-4 ' + (checkVisibilitySidebar() ? 'd-block' : 'd-none')"
      >
        <nest_modal_sidebar
          :bookingParts="bookingParts"
          :item="selectedItem"
          :index="index"
        />

        <div
          v-if="isMobile"
          class="bottomButtons d-flex justify-content-center mt-1"
        >
          <button
            class="modalBtn buttonPrimary d-flex align-items-center me-2"
            @click="closeButtonClicked()"
          >
            Cancel
          </button>
          <button
            @click="callbackSideBar()"
            :class="'modalBtn buttonSecondary d-flex align-items-center ' + (isAnyItemSlected ? 'disabled' : '') "
            :disabled="isAnyItemSlected"
          >
            Next
          </button>
        </div>
      </div>
      <div :class="'col-lg-8 ' + (checkVisibility() ? 'd-block' : 'd-none')">
        <!-- <nest_modal_parts :item="item"/> -->
        <nest_modal_parts :item="selectedItem" @event="callback" />
      </div>
    </div>
  </div>
</template>

<script>
import nest_modal_sidebar from "../../components/nest_components/nest_modal_sidebar.vue";
import nest_modal_parts from "../../components/nest_components/nest_modal_parts.vue";

export default {
  // props: ["bookingParts", "item", "isOpen", "index"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    item: Object,
    bookingParts: Array,
    index: Number,
  },

  components: {
    nest_modal_sidebar,
    nest_modal_parts,
  },

  data() {
    return {
      search: "",
      selectedItem: this.item ?? [],
      myModalEl: null,
      isItemSelected: false,
    };
  },

  watch: {
    isOpen: function () {
      this.setData();
      // this.selectedItem = []
      // this.selectedItem = JSON.parse(JSON.stringify(this.item));
    },

    // item: function () {
    //   this.isItemSelected = false
    //   this.selectedItem = []
    //   this.selectedItem = JSON.parse(JSON.stringify(this.item));
    // },
  },

  computed: {
    isMobile() {
      return window.screen.width < 992 ? true : false;
    },

    isAnyItemSlected() {
      return (this.selectedItem.parts && this.selectedItem.parts.length > 0 )? false : true;
    }
    
  },

  methods: {
    setData() {
      if (this.isOpen) {
        this.isItemSelected = false;
        this.selectedItem = [];
        this.selectedItem = JSON.parse(JSON.stringify(this.item));
      }
    },

    callbackSideBar() {
      this.isItemSelected = true;
    },

    checkVisibilitySidebar() {
      let res = true;
      if (this.isMobile) {
        res = !this.isItemSelected;
      }
      return res;
    },

    checkVisibility() {
      let res = true;
      if (this.isMobile) {
        res = this.isItemSelected;
      }
      return res;
    },

    callback(opt) {
      this.$emit("event", this.selectedItem, opt);
    },

    closeButtonClicked() {
      this.isItemSelected = false;
      this.$emit("event", this.selectedItem, "close");
    },
  },
};
</script>

<style></style>
