<template>
  <div class="gallery">
    <div
        class="galleryHead d-flex justify-content-between align-items-center primaryBg rounded p-2"
        @click="toggleAccordion"
    >
      <h5 class="mb-0 whiteText fw-bold">Gallery</h5>
      <!-- <button class="btn buttonPrimary rounded">View All</button> -->
      <span class="accordion-icon" :class="{ rotated: isAccordionOpen }"
      >&#9660;</span
      >
    </div>
  </div>
  <div class="galleryDes pt-2" v-if="isAccordionOpen || !isMobile">
    <p class="mb-0">
      <span class="orangeText">Note:</span> Please avoid uploading any financial
      documents.
    </p>
    <p class="mb-2 blueText">Max file size to upload is 10 MB</p>
    <div class="upload__box border rounded">
      <div class="upload__btn-box"></div>
      <div class="upload__img-wrap">
        <label class="upload__btn">
          <img src="../../assets/images/camer-icon.png" alt=""/>
          <input
              type="file"
              @change="onFileChange"
              ref="fileInput"
              class="upload__inputfile"
              accept="image/*,application/pdf"
          />
        </label>
        <div class="uploadCard" v-for="(file, index) in fileArray" :key="index">
          <div class="upload__img-box">
            <div class="img-bg">
              <div v-if="file.mime === 'pdf'" class="pdf-icon">
                <img src="../../assets/images/pdf-icon.svg" alt="PDF"/>
              </div>

              <img
                  data-fancybox="gallery"
                  :data-caption="file.title"
                  v-else
                  :src="file.url"
                  class="img-fluid"
              />

              <div class="upload__img-close" @click="removeFile(file)"></div>
            </div>
          </div>
          <div class="file-name-container d-flex align-items-center">
            <div class="finalName" v-if="file.editing">
              <input
                  type="text"
                  v-model="file.title"
                  class="form-control mb-0"
                  ref="editInput"
              />
              <span @click="toggleEditing(index)">
                <img
                    src="../../assets/images/check-icon.svg"
                    class="checkIcon"
                    alt="check-icon"
                />
              </span>
            </div>
            <div class="initialName" v-else>
              <p class="mb-0">{{ file.title }}</p>
              <img
                  v-if="!file.editing"
                  src="../../assets/images/edit-icon-2.png"
                  alt="Edit"
                  class="edit-icon ms-2"
                  @click="toggleEditing(index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const toast = useToast();

export default {
  data() {
    return {
      isAccordionOpen: true,
      isMobile: false,
      fileArray: [],
      maxLength: 10,
    };
  },

  computed: {
    ...mapGetters(["bookingImageUploaded", "bookingDocs", "removeBookingDocs"]),
  },

  watch: {
    bookingDocs: function () {
      this.fileArray = this.bookingDocs;
    },

    removeBookingDocs: function () {
      this.getBookingDocuments();
    },

    bookingImageUploaded: function () {
      this.$refs.fileInput.value = null;

      this.getBookingDocuments();
    },
  },

  mounted() {
    this.getBookingDocuments();
    this.$nextTick(() => {
      Fancybox.bind("[data-fancybox]", {
        // Custom options
      });
    });
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  methods: {
    ...mapActions([
      "bookingDocumentDelete",
      "uploadBookingImage",
      "getBookingDocs",
    ]),

    getBookingDocuments() {
      this.getBookingDocs({
        booking_id: this.$route.params.id,
      });
    },

    onFileChange(event) {
      this.updateImageDisplay(event);
    },

    updateImageDisplay(e) {
      const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
      const file = e.target.files[0];
      var type = "image";

      if (file.type === "application/pdf") {
        type = "pdf";
      }

      if (fileSize <= 10) {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        var obj = {
          title: file.name,
          url: url,
          thumbnail: url,
          mime: type,
        };

        this.fileArray = [obj].concat(this.fileArray); // [ 4, 3, 2, 1 ]

        this.uploadImage(e);
      } else {
        toast.error("File size should not be greater than 10MB.");
      }
    },

    uploadImage(e) {
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append("document", file);
      formData.append("booking_id", this.$route.params.id);
      formData.append("title", file.name);
      formData.append("timestamp", new Date().getTime());

      this.uploadBookingImage(formData);
    },

    removeFile(item) {
      this.$dialog.show({
        title: "Delete Image",
        text: "Are you sure you want to delete this document?",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.bookingDocumentDelete({
            id: item.id,
            booking_id: this.$route.params.id,
          });
        },
      });

      // this.$swal
      //   .fire({
      //     text: "Are you sure to delete this document?",
      //     icon: "error",
      //     cancelButtonText: "YES",
      //     confirmButtonText: "Cancel",
      //     showCancelButton: true,
      //   })
      //   .then((result) => {
      //     if (!result["isConfirmed"]) {
      //       this.bookingDocumentDelete({
      //         id: item.id,
      //         booking_id: this.$route.params.id,
      //       });
      //     }
      //   });
    },

    toggleEditing(index) {
      let isEditing = this.fileArray[index].editing;
      if (isEditing) {
        this.updateFileName(
            this.fileArray[index].title,
            this.fileArray[index].id
        );
      }
      this.fileArray[index].editing = !this.fileArray[index].editing;
      if (this.fileArray[index].editing) {
        this.$nextTick(() => {
          this.$refs.editInput[index].focus();
        });
      }
    },

    updateFileName(title, id) {
      let formData = new FormData();
      formData.append("booking_id", this.$route.params.id);
      formData.append("title", title);
      formData.append("timestamp", new Date().getTime());
      formData.append("id", id);

      this.uploadBookingImage(formData);
    },

    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 767;
    },
  },
};
</script>

<style>
.accordion-icon {
  transition: transform 0.3s;
  color: #fff;
  font-size: 20px;
}

.accordion-icon.rotated {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  /* .notesBody {
    display: none;
  }
  .notesBody[open] {
    display: block;
  } */
  .accordion-icon {
    display: none;
  }
}
</style>
