<template>
  <nest_header />
  <main class="evaluationPg">
    <div class="container">
      <div class="heading">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Evaluation </b> - Job ID <span class="orangeText">8448</span>
        </h4>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li><a class="greyText">My Account</a></li>
          <li>
            <a class="greyText"
              >Service & Repair History</a
            >
          </li>
          <li>
            <a :href="'/job/detail/'+jobId" class="greyText">Job Detail</a>
          </li>
          <li class="blueText">Evaluation - Job ID 8448</li>
        </ul>
      </div>
      <div class="evaluationSec">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Located at</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>House</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Manufacturer</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>AIRE-FLO</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Heat</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Strip</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Outer Placement</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Ground</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Air Handler Location</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Attic</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Manufacture Date</label>
              <input type="date" class="form-control" />
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Capacity AC (tons)</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>1.5 tons</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <h6 class="mb-1">Is the system sized properly?</h6>
              <div class="radioCheck d-flex mt-3">
                <div class="form-check p-0 me-3">
                  <input type="radio" id="yes" name="radio-group" checked />
                  <label for="yes">Yes</label>
                </div>
                <div class="form-check p-0">
                  <input type="radio" id="no" name="radio-group" />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">SEER Rating</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>14.00</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <label class="mb-1">Refrigerant Type</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>cfc</option>
                <option value="1">One</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-4 col-sm-12 col-12">
            <div class="form-group mb-3">
              <h6 class="mb-1">
                Is there advanced air filtration installed on this unit?
              </h6>
              <div class="radioCheck d-flex mt-3">
                <div class="form-check p-0 me-3">
                  <input type="radio" id="yes" name="radio-group" checked />
                  <label for="yes">Yes</label>
                </div>
                <div class="form-check p-0">
                  <input type="radio" id="no" name="radio-group" />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="from-group">
              <label class="orangeText" for="notes ">Notes</label>
              <textarea
                name="notes"
                rows="5"
                placeholder="Enter notes here"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <button class="btn buttonPrimary mt-5 mx-auto d-table rounded">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import nest_header from "../../components/nest_components/nest_header.vue";
export default {
  components: {
    nest_header,
  },
};
</script>
