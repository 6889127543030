<template>
  <main class="initialCheckPg">
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Initial Check </b> - Job ID
          <span class="orangeText">{{ jobId }}</span>
        </h4>
        <nest_notes_modal />
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li><a class="greyText">My Account</a></li>
          <li>
            <a class="greyText">Service & Repair History</a>
          </li>
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Detail</a>
          </li>
          <li class="blueText">Initial Check - Job ID {{ jobId }}</li>
        </ul>
      </div>

      <div class="initialCheckSec">
        <div
          class="initialCheckHead d-flex align-items-center justify-content-between my-4"
        >
          <h5 class="blueText mb-0">Split System</h5>
          <ul class="list-inline mb-0 d-flex">
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/green-icon.png"
                class="me-2"
                alt="green-icon"
              />
              <p class="mb-0">Operating as per manufacturer's specification</p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/duck-head-icon.png"
                class="me-2"
                alt="yellow-icon"
              />
              <p class="mb-0">
                Not operating as per manufacturer's specification
              </p>
            </li>
            <li class="me-2 d-flex align-items-center">
              <img
                src="../../assets/images/electric-head-icon.png"
                class="me-2"
                alt="red-icon"
              />
              <p class="mb-0">Non Operation / Critical</p>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12 col-12">
            <nest_timeline />
          </div>
          <div class="col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="row">
              <div
                class="col-lg-12 col-md-12 col-sm-12 col-12"
                v-for="(item, index) in initialChecksList.head"
                :key="index"
              >
                <div
                  class="form-group d-flex justify-content-between align-items-center mb-3"
                >
                  <h6 class="mb-0">{{ item.option }}</h6>
                  <div class="radioCheck d-flex">
                    <div class="form__radio-group me-3">
                      <input
                        :id="item.option + '-yes'"
                        value="1"
                        type="radio"
                        class="form__radio-input"
                        :name="item.option"
                        v-model="operationalCheck"
                      />
                      <label
                        :for="item.option + '-yes'"
                        class="form__radio-label"
                      >
                        <span class="form__radio-button"></span>
                        <span class="form__radio-label-text">Yes</span>
                      </label>
                    </div>
                    <div class="form__radio-group">
                      <input
                        :id="item.option + '-no'"
                        type="radio"
                        value="0"
                        class="form__radio-input"
                        :name="item.option"
                        v-model="operationalCheck"
                      />
                      <label
                        :for="item.option + '-no'"
                        class="form__radio-label"
                      >
                        <span class="form__radio-button"></span>
                        No</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-6"
                v-for="(item, index) in getOptions()"
                :key="index"
              >
                <div class="form-group mb-3 lightGreyBg p-3 rounded">
                  <h6 class="mb-2">{{ item.option }}</h6>
                  <div class="radioCheck customRadiocheck d-flex">
                    <div class="form__radio-group">
                      <input
                        v-on:change="onChange(item, 1)"
                        :checked="item.value == 1"
                        type="radio"
                        class="form__radio-input"
                        :value="1"
                        :id="item.option + '-green'"
                        :name="item.option"
                      />
                      <label
                        :for="item.option + '-green'"
                        class="form__radio-label greenRadio"
                      >
                        <span class="form__radio-button"></span>
                      </label>
                    </div>
                    <div class="form__radio-group">
                      <input
                        v-on:change="onChange(item, 2)"
                        :checked="item.value == 2"
                        type="radio"
                        class="form__radio-input"
                        :value="2"
                        :id="item.option + '-yellow'"
                        :name="item.option"
                      />
                      <label
                        :for="item.option + '-yellow'"
                        class="form__radio-label yellowRadio"
                      >
                        <span class="form__radio-button"></span>
                      </label>
                    </div>
                    <div class="form__radio-group">
                      <input
                        v-on:change="onChange(item, 3)"
                        :checked="item.value == 3"
                        type="radio"
                        class="form__radio-input"
                        :value="3"
                        :id="item.option + '-red'"
                        :name="item.option"
                      />
                      <label
                        :for="item.option + '-red'"
                        class="form__radio-label redRadio"
                      >
                        <span class="form__radio-button"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bottomButtons mt-5 d-flex justify-content-center">
                <button
                  @click="backButtonClicked()"
                  class="btn buttonDark rounded text-uppercase me-2"
                >
                  Back
                </button>
                <button
                  @click="nextButtonClicked()"
                  class="btn buttonPrimary text-uppercase rounded"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import nest_timeline from "../../components/nest_components/nest_timeline.vue";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    nest_timeline,
    nest_notes_modal,
  },

  computed: {
    ...mapGetters([
      "initialChecksList",
      "apiCallback",
      "storeChecksResponse",
      "getDiagnosisOptions",
    ]),
  },

  data() {
    return {
      jobId: this.$route.params.id,
      unit_id: this.$route.query.unit_id,
      operationalCheck: 1,
      selectedOptions: [],
      options: [],
      existingOptions: [],
    };
  },

  mounted() {
    this.getInitialChecks({
      booking_id: this.jobId,
    });
  },

  watch: {
    initialChecksList: function () {
      this.options = this.initialChecksList.data;
      this.getDiagnosisOption({
        booking_id: this.jobId,
        diagnosis_type: "initial_checks",
      });
    },

    getDiagnosisOptions: function () {
      this.setOptions();
    },

    storeChecksResponse: function () {
      this.$router.push({
        path: `/equipment/${this.$route.params.id}`,
        query: { unit_id: this.unit_id },
      });
    },
  },

  methods: {
    ...mapActions(["getInitialChecks", "storeChecks", "getDiagnosisOption"]),

    onChange(data, value) {
      data.value = value;
    },

    setOptions() {
      this.existingOptions = JSON.parse(
        this.getDiagnosisOptions[0].diagnosis_data
      );
      let self = this;
      console.log("exit", self.existingOptions);
      this.options = this.options.map(function (item) {
        self.existingOptions.forEach(function (obj) {
          if (
            parseInt(obj.diagnostic_option_id) ===
            parseInt(item.diagnostic_option_id)
          ) {
            item.value = obj.value;
          }
        });
        return item;
      });

      this.operationalCheck = this.existingOptions[0].is_disable ?? 1;
    },

    getOptions() {
      let self = this;
      this.selectedOptions = this.options.filter(function (item) {
        return (
          self.operationalCheck == 1 ||
          (self.operationalCheck == 0 && item.is_disable == 0)
        );
      });
      return this.selectedOptions;
    },

    nextButtonClicked() {
      this.storeChecks({
        booking_id: this.jobId,
        diagnosis_type: "initial_checks",
        diagnosis_data: JSON.stringify(this.selectedOptions),
      });
    },

    backButtonClicked() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
