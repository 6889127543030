<template>
  <div class="p-2">
    <!-- <div class="modal-header">
      <h5 class="modal-title">Scan QR Code or Barcode</h5>
      <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
    </div> -->
    <div
      class="barcodeHeader d-flex justify-content-between align-items-center primaryBg mb-3 p-2 rounded"
    >
      <h5 class="mb-0 whiteText fw-bold">Scan QR Code</h5>
      <!-- <div class="closebutton d-flex justify-content-end">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        ></button>
      </div> -->
      <div class="closebutton d-flex justify-content-end">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        ></button>
      </div>
    </div>
    <div class="modal-body">
      <video ref="video" class="video"></video>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";

export default {
  props: ["showModal"],
  data() {
    return {
      codeReader: null,
    };
  },
  watch: {
    showModal(newValue) {
      console.log(newValue);
      if (newValue) {
        this.startScanner();
      } else {
        this.stopScanner();
      }
    },
  },
  mounted() {
    this.codeReader = new BrowserMultiFormatReader();
  },
  methods: {
    startScanner() {
      this.codeReader
        .decodeFromVideoDevice(null, this.$refs.video, (result, err) => {
          if (result) {
            console.log("Scanned result:", result.text);
            this.$emit("scan-result", result.text);
            this.closeModal();
          }
          if (err) {
            console.error(err);
          }
        })
        .catch((err) => console.error(err));
    },
    stopScanner() {
      if (this.codeReader) {
        this.codeReader.reset();
      }
    },
    closeModal() {
      this.stopScanner();
      this.$emit("close");
    },
  },
};
</script>

<style>
.video {
  width: 100%;
  max-width: 600px;
  /* border: 1px solid black; */
}
.barcodeHeader .btn-close {
  font-size: 12px;
  opacity: 1;
  padding: 0.3rem;
  border: 2px solid #000;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  filter: invert(1);
}
</style>
