import {createRouter, createWebHistory} from "vue-router";
import silentLogin from "@/components/silent_login/Login.vue";
import ssoLogin from "@/components/sso/Login.vue";
import appointmentDetail from "@/views/appointment/AppointmentDetail.vue";
import quoteDetail from "@/views/quote/QuoteDetail.vue";
import editQuote from "@/views/quote/QuoteEdit.vue";
import notfound from "@/views/NotFound.vue";
import NProgress from "nprogress";
import login from "../views/nest_views/login.vue";
import dashboard from "../views/nest_views/dashboard.vue";
import servicerepairhistory from "../views/nest_views/service-repair-history.vue";
import jobdetail from "../views/nest_views/job-detail.vue";
import selectunit from "../views/nest_views/select-unit.vue";
import evaluation from "../views/nest_views/evaluation.vue";
import evaluationPreCheck from "../views/nest_views/evaluation-pre-check.vue";
import initialCheck from "../views/nest_views/initial-check.vue";
import outdoorequipment from "../views/nest_views/outdoor-equipment.vue";
import equipment from "../views/nest_views/indoor-outdoor-equipment.vue";
import indoorequipment from "../views/nest_views/indoor-equipment.vue";
import summary from "../views/nest_views/summary.vue";
import finalEvaluation from "../views/nest_views/final-evaluation.vue";
import summaryDetail from "../views/nest_views/summary-detail.vue";
import editSummary from "../views/nest_views/edit-summary.vue";
import success from "../views/nest_views/nest-success.vue";
import failure from "../views/nest_views/nest-success.vue";

const routes = [
    {
        path: "/",
        name: "silentLogin",
        component: silentLogin,
    },
    {
        path: "/sso",
        name: "ssoLogin",
        component: ssoLogin,
    },
    {
        path: "/appointment/detail/:id",
        name: "AppointmentDetail",
        meta: {
            requiresAuth: true, // Add meta field to indicate protected route
        },
        component: appointmentDetail,
    },
    {
        path: "/quote/detail/:id",
        name: "QuoteDetail",
        meta: {
            requiresAuth: true, // Add meta field to indicate protected route
        },
        component: quoteDetail,
    },
    {
        path: "/quote/edit/:id",
        name: "QuoteEdit",
        meta: {
            requiresAuth: true, // Add meta field to indicate protected route
        },
        component: editQuote,
    },
    {
        path: "/not-found",
        name: "NotFound",
        meta: {
            requiresAuth: true, // Add meta field to indicate protected route
        },
        component: notfound,
    },
    // nest_view router
    {
        path: "/login",
        name: "login",
        component: login,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: dashboard,
    },
    {
        path: "/service-repair-history",
        name: "service-repair-history",
        component: servicerepairhistory,
    },
    {
        path: "/job/detail/:id",
        name: "job-detail",
        component: jobdetail,
    },
    {
        path: "/select/unit/:id",
        name: "select-unit",
        component: selectunit,
    },
    {
        path: "/evaluation",
        name: "evaluation",
        component: evaluation,
    },
    {
        path: "/evaluation-pre-check",
        name: "evaluation-pre-check",
        component: evaluationPreCheck,
    },
    {
        path: "/initial-check/:id",
        name: "initial-check",
        component: initialCheck,
    },
    {
        path: "/outdoor-equipment/:id",
        name: "outdoor-equipment",
        component: outdoorequipment,
    },
    {
        path: "/equipment/:id",
        name: "equipment",
        component: equipment,
    },
    {
        path: "/indoor-equipment",
        name: "indoor-equipment",
        component: indoorequipment,
    },
    {
        path: "/summary/:id",
        name: "summary",
        component: summary,
    },
    {
        path: "/final-evaluation/:id",
        name: "final-evaluation",
        component: finalEvaluation,
    },
    {
        path: "/summary-detail/:id",
        name: "summary-detail",
        component: summaryDetail,
    },
    {
        path: "/edit-summary/:id",
        name: "edit-summary",
        component: editSummary,
    },{
        path: "/success",
        name: "success",
        component: success,
    },{
        path: "/failure",
        name: "failure",
        component: failure,
    },
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'NotFound',
    //   meta: {
    //     requiresAuth: true // Add meta field to indicate protected route
    //   },
    //   component: notfound
    // },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    // {
    //   path: '/products',
    //   name: 'Products',
    //   component: () => import('../views/products/ProductList.vue')
    // },
    // {
    //   path: '/products/create',
    //   name: 'Product Create',
    //   component: () => import('../views/products/ProductCreate.vue')
    // },
    // {
    //   path: '/products/edit/:id',
    //   name: 'ProductEdit',
    //   component: () => import('../views/products/ProductEdit.vue')
    // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        let tempUser = localStorage.getItem("user") ?? null;
        if (tempUser != null) {
            // User is authenticated, proceed to the route
            next();
        } else {
            // User is not authenticated, redirect to login
            alert("Not Authorized");

            // next({
            //       path: '/login'
            //   })
        }
    } else {
        // Non-protected route, allow access
        next();
    }
});

export default router;
