<template>
  <div>
    <div
      class="modal fade modal-md show"
      id="modalDiscard"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content discard-wrap">
          <div class="modal-header">
            <button
              @click="closeModal"
              type="button"
              class="btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="@/assets/images/cross-btn.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="contPopup qrPopup text-center">
              <h1>QR Code</h1>
              <p>
                Present this<span> QR code </span>to the customer to view the quote details.
              </p>
              <p>
                Guide them on scanning the 
                <span> QR code </span>using their device..
              </p>
              <div class="qrCode">
                <img :src=qrResponse.qrcodeUrl   alt="qr-code" />
              </div>

              <!-- <label class="myCheckText">
                <input v-model="check" type="checkbox" class="myCheck me-2" />I
                accept that I ill collect TradeCall fees from consumer.</label
              >
              <div class="text-center mt-10 d-flex justify-content-evenly mt-4">
                <span class="btn main-button mb-4 disBtn" @click="closeModal"
                  >Dismiss</span
                >
                <span class="btn main-button mb-4" @click="confirm"
                  >Submit</span
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {},

  data() {
    return {
      check: false,
    };
  },
  computed: {
    ...mapGetters(["quoteDetail", "apptDetail","qrResponse"]),
  },

  watch: {},

  mounted() {},

  methods: {
    ...mapActions(["setCommponent"]),

    editQuoteClicked() {
      this.setCommponent("QuoteItems");
    },

    closeModal() {
      this.check = false;
      this.$emit("event", "close");
    },

    confirm() {
      if (this.check) {
        this.check = false;
        this.$emit("event", "confirm");
      } else {
        toast.error("You must agree to above condition to continue.");
      }
    },
  },
};
</script>

<style>
.modals-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modals {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.discard-wrap {
  border-radius: 20px;
  box-shadow: 0 0 10px #fff;
}

.discard-wrap .modal-header {
  border: none;
}

.continue-popup .modal-header .close {
  position: absolute;
  top: 30px;
  right: 30px;
  background: #ff5b01;
  opacity: 1;
  padding: 0;
  height: 27px;
  width: 27px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0 2px 3px #444;
  font-weight: 100;
}

.continue-popup .modal-header {
  padding: 0;
  border: none;
}

.continue-popup {
  border-radius: 12px;
  box-shadow: 0 0 10px #fff;
  border: none;
}

.contPopup h1 {
  color: #003087;
  font-size: 25px;
  font-weight: 600;
  text-shadow: 0 1px 3px #666;
  margin-top: -30px;
}

.contPopup p {
  color: #000000;
  font-size: 15px;
}

.btnClose {
  position: absolute;
  background: none;
  right: 10px;
  padding: 0;
  border: none;
  border-radius: 50px;
  z-index: 9;
  font-weight: bold;
}

.myCheckText {
  color: #ff5b01;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.myCheck {
  height: 15px;
  width: 15px;
  accent-color: #ff5b01;
}

.disBtn {
  background-color: #003087 !important;
}
.qrPopup p span {
  color: #ff5b01;
}
.qrCode{
    margin: 3rem 0 3rem;
    width:200px;
    margin: 3rem auto;
}
.qrCode img{
  width:100%;
}



@media screen and (max-width: 767px) {
}
</style>
