import { createApp } from "vue";
import App from "./App.vue";
import "../src/assets/style/custom.css";
import "../src/assets/scss/nestview.css";

import router from "./router";
import Store from "./store/Store";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "font-awesome/css/font-awesome.min.css";
import "../node_modules/nprogress/nprogress.css";
import "./store/axios.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import VueZxingScanner from "vue-zxing-scanner";

const app = createApp(App);
app.use(router);
app.use(Store);
app.use(VueZxingScanner);
app.use(VueViewer);

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
  },
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {};
app.use(Toast, options);

app.config.devtools = true;

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
app.use(VueSweetalert2);

import Modal from "./plugins/FDialog.js";
app.use(Modal);

import FLoader from "./plugins/FLoader.js";
app.use(FLoader);

const globals = app.config.globalProperties;
export { globals };

app.mount("#app");

// Before you create app
// app.config.devtools = process.env.NODE_ENV === 'development';
