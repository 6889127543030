<template>
  <div>
  
    <div v-if="isJobDetailCalled" class="container">
     
      <div class="heading">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Job Details </b> - Job ID
          <span class="orangeText">{{ jobDetail.id }}</span>
        </h4>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-3">
        <ul class="d-flex list-inline mb-0">
          <li class="blueText">Job Details</li>
        </ul>
      </div>
      <div v-if="isConsumerQuoteDeclined"
        class="declinedBanner declinedBg rounded d-flex align-items-center justify-content-center p-3 mb-3">
        <img src="../../assets/images/declined-icon.png" alt="declined-icon" class="me-3" />
        <h3 class="mb-0 whiteText">Consumer has declined the quote</h3>
      </div>
      <div class="jobDetail">
        <div class="row">
          <div class="col-lg-3 col-md-5 col-sm-12 col-12">
            <div class="jobDetailLeft">
              <div class="row">
                <div class="col-md-12 col-sm-6">
                  <nest_consumer_detail v-if="!isMobile" />
                </div>
                <div v-if="jobDetail.depp_schedule" class="col-md-12 col-sm-6">
                  <div class="mobilePartnerQrSec">
                    <div class="partnerSec mt-4">
                      <h6 v-if="jobDetail.is_from_nest" class="mb-3 blueText fw-bold">
                        fyxify Contact Details
                      </h6>
                      <h6 v-else class="mb-3 blueText fw-bold">
                        Partner Details
                      </h6>

                      <ul v-if="!jobDetail.depp_schedule.show_consumer_as_resident" class="list-inline mb-0 mt-3">
                        <li class="px-3 py-4 rounded border">
                          <img :src="jobDetail.depp_schedule.company_logo" class="partnerLogo d-table mx-auto mb-4"
                            :alt="jobDetail.depp_schedule.company_name" />
                          <a :href="'tel:' + jobDetail.depp_schedule.phone" class="mx-auto d-table blueText"
                            target="_blank">
                            {{ jobDetail.depp_schedule.phone }}
                          </a>
                        </li>
                      </ul>
                      <ul v-else class="list-inline mb-0 mt-3">
                        <li class="px-3 py-4 rounded border">
                          <img src="@/assets/images/fyxifyLogo.png" class="partnerLogo d-table mx-auto mb-4"
                            :alt="jobDetail.depp_schedule.company_name" />
                          <a :href="'tel:' + jobDetail.depp_schedule.phone" class="mx-auto d-table blueText"
                            target="_blank">
                            {{ jobDetail.depp_schedule.phone }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="is_QR" class="qrCodeSec mt-4">
                      <h6 class="mb-3 blueText fw-bold">QR Code</h6>
                      <ul class="rounded border list-inline mb-0 mt-3">
                        <li class="px-3 py-4 ">
                          <img :src="QRData.qrcodeUrl" class="qrCode-black d-table mx-auto" :alt="QRData.qrcodeUrl" />
                        </li>
                        <li>
                          Present this
                          <span class="orangeText">QR code </span> to the
                          customer to view the quote details.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-7 col-sm-12 col-12">
            <div class="evaluationStatus pb-4 mb-4 border-bottom">
              <div class="row d-flex justify-content-between">
                <div class="col-lg-7 col-md-6 col-sm-6 col-12">
                  <div class="allActionButtons d-flex justify-content-between flex-wrap">
                    <span v-for="(item, index) in actions" :key="item">
                      <button @click="actionClicked(item.name)" v-if="item.name == 'Suspend Job'"
                        class="btn fancyRedButton mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                          <!-- {{ item.name }} -->

                          <div class="buttonCntnt d-flex align-items-center">
                            <div class="leftImgs me-2">
                              <img :src="item.originalImg" class="originalcon" alt="" />
                              <img :src="item.hoverImg" class="hoverIcon" alt="" />
                            </div>
                            <p class="mb-0">{{ item.name }}</p>
                          </div>
                          <div class="rightImgs">
                            <img src="../../assets/images/angle-right-icon.png" alt="" class="originalcon" />
                            <img src="../../assets/images/angle-blue-icon.png" alt="" class="hoverIcon" />
                          </div>
                        </div>
                      </button>
                      <button @click="actionClicked(item.name)" v-else-if="item.name == 'View Evaluation'"
                        class="btn fancyBlueButton mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                          <!-- {{ item.name }} -->

                          <div class="buttonCntnt d-flex align-items-center">
                            <div class="leftImgs me-2">
                              <img :src="item.originalImg" class="originalcon" alt="" />
                              <img :src="item.hoverImg" class="hoverIcon" alt="" />
                            </div>
                            <p class="mb-0">{{ item.name }}</p>
                          </div>
                          <div class="rightImgs">
                            <img src="../../assets/images/angle-right-icon.png" alt="" class="originalcon" />
                            <img src="../../assets/images/angle-blue-icon.png" alt="" class="hoverIcon" />
                          </div>
                        </div>
                      </button>
                      <button @click="actionClicked(item.name)" v-else-if="item.name == 'Edit Evaluation'"
                        class="btn fancyOrangeButton mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                          <!-- {{ item.name }} -->

                          <div class="buttonCntnt d-flex align-items-center">
                            <div class="leftImgs me-2">
                              <img :src="item.originalImg" class="originalcon" alt="" />
                              <img :src="item.hoverImg" class="hoverIcon" alt="" />
                            </div>
                            <p class="mb-0">{{ item.name }}</p>
                          </div>
                          <div class="rightImgs">
                            <img src="../../assets/images/angle-right-icon.png" alt="" class="originalcon" />
                            <img src="../../assets/images/angle-blue-icon.png" alt="" class="hoverIcon" />
                          </div>
                        </div>
                      </button>
                      <button v-else @click="actionClicked(item.name)" :class="index % 2 === 0
                        ? 'btn fancyOrangeButton mb-3'
                        : 'btn fancyBlueButton mb-3'
                        ">
                        <div class="d-flex align-items-center justify-content-between">
                          <!-- {{ item.name }} -->

                          <div class="buttonCntnt d-flex align-items-center">
                            <div class="leftImgs me-2">
                              <img :src="item.originalImg" class="originalcon" alt="" />
                              <img :src="item.hoverImg" class="hoverIcon" alt="" />
                            </div>
                            <p class="mb-0">{{ item.name }}</p>
                          </div>
                          <div class="rightImgs">
                            <img src="../../assets/images/angle-right-icon.png" alt="" class="originalcon" />
                            <img src="../../assets/images/angle-blue-icon.png" alt="" class="hoverIcon" />
                          </div>
                        </div>
                      </button>
                    </span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <h5 class="mb-0 fw-bold orangeText text-capitalize">
                    Job Status:
                    <span class="oceanText">{{
                      getCapitalize(jobDetail.status_text)
                      }}</span>
                  </h5>
                  <p class="mb-0">{{ getJobStatusDescription() }} <br /></p>
                </div>
              </div>
            </div>
            <div class="bookingHead d-flex justify-content-between mb-4">
              <div v-if="jobDetail.services" class="serviceType">
                <h6 class="mb-2 greyText">Service Type</h6>
                <h5 class="mb-0 blueText">{{ jobDetail.services[0].title }}</h5>
              </div>
              <div class="booking text-end">
                <h6 class="mb-2 d-flex justify-content-end align-items-center">
                  Booking ID
                  <span class="secondarybg whiteText ms-2">{{
                    jobDetail.id
                    }}</span>

                  <div v-if="isDeclineQuoteEnable || screenWidth <= 576" class="dropdown ms-2">
                    <img src="../../assets/images/vertical-dot-icon.png" alt="vertical-dot-icon" class="dropdown-toggle"
                      data-bs-toggle="dropdown" aria-expanded="false" />
                    <ul class="dropdown-menu p-0">
                      <li v-if="isDeclineQuoteEnable">
                        <button class="dropdown-item d-flex slign-items-center p-2" @click="declineCallButtonClicked()">
                          <img src="../../assets/images/cross-btn-1.png" class="me-2" alt="" />
                          Decline Quote
                        </button>
                      </li>
                      <div class="ViewMobileList">
                        <li>
                          <button class="dropdown-item d-flex slign-items-center p-2" data-bs-toggle="modal"
                            data-bs-target="#profileModal">
                            <img src="../../assets/images/cross-btn-1.png" class="me-2" alt="" />
                            Customer Profile
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item d-flex slign-items-center p-2" data-bs-toggle="modal"
                            data-bs-target="#partnerModal">
                            <img src="../../assets/images/cross-btn-1.png" class="me-2" alt="" />
                            Contact fyxify
                          </button>
                        </li>
                        <li v-if="is_QR">
                          <button class="dropdown-item d-flex slign-items-center p-2" data-bs-toggle="modal"
                            data-bs-target="#qrModal">
                            <img src="../../assets/images/cross-btn-1.png" class="me-2" alt="" />
                            QR Code
                          </button>
                        </li>
                      </div>
                    </ul>
                  </div>
                </h6>
                <h6 class="mb-0">{{ jobDetail.booking_date }}</h6>
              </div>
            </div>
            <div class="address mobileViewAddress pb-4">
              <div v-if="jobDetail.address" class="serviceAdd mb-2">
                <h6 class="mb-0 blueText mb-0">Service Address</h6>
                <p class="blackText mb-0">
                  {{ jobDetail.address.address }}
                </p>
              </div>
            </div>
            <nest_notes v-if="bookingNotes" :isFromJobDetail="true" />
            <div class="address webViewAddress py-4">
              <div v-if="jobDetail.address" class="serviceAdd mb-2">
                <h6 class="mb-0 blueText mb-0">Service Address</h6>
                <p class="blackText mb-0">
                  {{ jobDetail.address.address }}
                </p>
              </div>
            </div>
            <nest_gallery />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="cancelBookingModal" ref="cancelBookingModal" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-5">
            <nest_cancel_booking :booking="jobDetail" :markAsCompleteObject="markAsCompleteObject"
              @event="cancelCallback" />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="iframeModal" ref="iframeModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="closebutton d-flex justify-content-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modalCntnt pt-3">
              <iframe :src="techUrl"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Profile Modal -->
    <div class="modal fade" id="profileModal" ref="profileModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="closebutton d-flex justify-content-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modalCntnt pt-3">
              <nest_consumer_detail v-if="isMobile" />
            </div>
          </div>
        </div>
      </div>

      <!-- <nest_consumer_detail /> -->
    </div>

    <!-- Partner Modal -->
    <div class="modal fade" id="partnerModal" ref="partnerModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="closebutton d-flex justify-content-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="jobDetail.depp_schedule" class="modalCntnt pt-3">
              <div class="partnerSec">
                <h6 class="mb-3 blueText fw-bold text-center">
                  fyxify Contact Details
                </h6>
                <ul class="list-inline mb-0 mt-3">
                  <li class="px-3 py-4 rounded">
                    <img :src="jobDetail.depp_schedule.company_logo" class="partnerLogo d-table mx-auto mb-4"
                      :alt="jobDetail.depp_schedule.company_name" />
                    <a :href="'tel:' + jobDetail.depp_schedule.phone" target="_blank" class="mx-auto d-table blueText">
                      <!-- <img
                        :src="jobDetail.depp_schedule.company_call_logo"
                        class="callIcon d-table mx-auto"
                        :alt="jobDetail.depp_schedule.company_name"
                      /> -->
                      {{ jobDetail.depp_schedule.phone }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- QR Modal -->
    <div class="modal fade" id="qrModal" ref="qrModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true" v-if="isMobile">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-3">
            <div class="closebutton d-flex justify-content-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modalCntnt pt-3">
              <div v-if="is_QR" class="qrCodeSec">
                <h6 class="mb-3 blueText fw-bold text-center">QR Code</h6>
                <ul class="list-inline mb-0 mt-3">
                  <li class="px-3 py-4 rounded">
                    <img :src="QRData.qrcodeUrl" class="qrCode-black d-table mx-auto" :alt="QRData.qrcodeUrl" />
                  </li>
                  <li class="text-center">
                    Present this <span class="orangeText">QR code </span> to the
                    customer to view the quote details.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nest_consumer_detail from "../../components/nest_components/nest_consumer_detail.vue";
import nest_notes from "../../components/nest_components/nest_notes.vue";
import nest_gallery from "../../components/nest_components/nest_gallery.vue";
import nest_cancel_booking from "../../components/nest_components/nest_cancel_booking.vue";
import { mapActions, mapGetters } from "vuex";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
import { faL } from "@fortawesome/free-solid-svg-icons";
const toast = useToast();

export default {
  data() {
    return {
      actions: [],
      is_QR: false,
      isDeclineQuoteEnable: false,
      isJobDetailCalled: false,
      isConsumerQuoteDeclined: false,
      techUrl: "",
      screenWidth: "",
      showCreateVerbage: false,
    };
  },
  computed: {
    ...mapGetters([
      "jobDetail",
      "apiCallback",
      "bookingNotes",
      "QRData",
      "bookingDiagnosisItems",
      "storeChecksResponse",
      "updateJobStatusResponse",
      "resumeJobResponse",
      "endJobResponse",
      "markAsCompleteObject",
      "resubmitQuoteResponse",
    ]),

    isMobile() {
      return window.screen.width < 576 ? true : false;
    },
  },

  components: {
    nest_consumer_detail,
    nest_notes,
    nest_gallery,
    nest_cancel_booking,
  },
  mounted() {
    this.screenWidth = screen.width;
    this.getBookingDetails({
      booking_id: this.$route.params.id,
    });
    this.getData();
    this.getNotesData();

    const modalElement = document.getElementById("cancelBookingModal");
    modalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);

    if (this.isMobile) {
      const qrModalElement = document.getElementById("qrModal");
      qrModalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
      qrModalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);

      const consumerModalElement = document.getElementById("profileModal");
      consumerModalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
      consumerModalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);

      const partnerModalElement = document.getElementById("partnerModal");
      partnerModalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
      partnerModalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);
    }

  },

  beforeUnmount() {
    const modalElement = document.getElementById("cancelBookingModal");
    modalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.removeEventListener(
      "hidden.bs.modal",
      this.removeOverflowHidden
    );

    if (this.isMobile) {
      const consumerModalElement = document.getElementById("profileModal");
      consumerModalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
      consumerModalElement.removeEventListener(
        "hidden.bs.modal",
        this.removeOverflowHidden
      );

      const partnerModalElement = document.getElementById("partnerModal");
      partnerModalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
      partnerModalElement.removeEventListener(
        "hidden.bs.modal",
        this.removeOverflowHidden
      );

      const qrModalElement = document.getElementById("qrModal");
      qrModalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
      qrModalElement.removeEventListener(
        "hidden.bs.modal",
        this.removeOverflowHidden
      );
    }

  },

  watch: {
    updateJobStatusResponse: function () {
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },

    resubmitQuoteResponse: function () {
      toast.success("Quote has been Resubmitted successfully.");
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },

    endJobResponse: function () {
      toast.success("Job has been ended successfully.");
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },

    resumeJobResponse: function () {
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },

    jobDetail: function () {
      this.actions = this.getJobStatusWithDescriptionForJobStatus("actions");
      this.setQR();
      this.isJobDetailCalled = true;
    },

    storeChecksResponse: function () {
      this.$router.push({
        path: `/edit-summary/${this.$route.params.id}`,
      });
    },

    markAsCompleteObject: function () {
      this.modal = new Modal(this.$refs.cancelBookingModal);
      this.modal.show();
    },
  },

  methods: {
    addOverflowHidden() {
      document.documentElement.classList.add("overflow-hidden");
    },
    removeOverflowHidden() {
      document.documentElement.classList.remove("overflow-hidden");
    },

    ...mapActions([
      "getBookingDetail",
      "getBookingNotes",
      "getQRData",
      "getBookingDiagnosisItems",
      "storeChecks",
      "updateJobStatus",
      "resumeJob",
      "endJob",
      "getMarkAsCompleteObject",
      "resubmitQuote",
      "getBookingDetails",
    ]),

    cancelCallback() {
      this.modal.hide();
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },

    getData() {
      this.getBookingDetail({
        booking_id: this.$route.params.id,
      });
    },



    setQR() {
      if (this.is_QR) {
        this.getQRData({
          job_id: this.$route.params.id,
          job_source: "sar",
        });
      }
    },

    getNotesData() {
      this.getBookingNotes({
        booking_id: this.$route.params.id,
      });
    },

    getJobStatusWithDescriptionForJobStatus(type) {
      let description = "";
      let actions = [];
      let status = this.jobDetail.status;
      this.is_QR = false;
      this.isDeclineQuoteEnable = false;
      this.isConsumerQuoteDeclined = false;
      this.showCreateVerbage = false;

      //1 : accepted
      if (status == 1) {
        description = "Time to check the unit and identify the fault.";
      }
      // 2: en_route
      else if (status == 2) {
        description = "Time to check the unit and identify the fault.";
      }
      //3 : arrived
      else if (status == 3) {
        this.showCreateVerbage = true;
        actions.push({
          name: "Start Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        description = "Time to check the unit and identify the fault.";
      }
      //4 :diagnosis_started
      else if (status == 4) {
        actions.push({
          name: "Resume Evaluation",
          originalImg: require("../../assets/images/resume-evaluation-icon.png"),
          hoverImg: require("../../assets/images/resume-evaluation-hover-icon.png"),
        });
        if (!this.jobDetail.depp_schedule.is_allied) {
          this.isDeclineQuoteEnable = true;
        }
        description =
          "Evaluation in progress. Create a quote for consumer's approval.";
      }
      //5 :diagnosis_completed
      else if (status == 5) {
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        actions.push({
          name: "Suspend Job",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        this.isDeclineQuoteEnable = true;
        this.is_QR = true;

        description =
          this.jobDetail.consumer.first_name +
          " is approving the quote. Look out for them; they may need your help.";
      }

      //6: quote_ready
      else if (status == 6) {
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        if (!this.jobDetail.depp_schedule.is_allied) {
          this.isDeclineQuoteEnable = true;
        }
        this.is_QR = true;

        description =
          "Congratulations! The customer has approved the quote. Start fixing the unit as per the approved quote.";
      }

      //7: started
      else if (status == 7) {
        actions.push({
          name: "Suspend Job",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        actions.push({
          name: "End Job",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        if (!this.jobDetail.depp_schedule.is_allied) {
          this.isDeclineQuoteEnable = true;
        }
        actions.push({
          name: "Approved Quote",
          originalImg: require("../../assets/images/approved-quote-icon.png"),
          hoverImg: require("../../assets/images/approved-qoute-hover-icon.png"),
        });
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        this.is_QR = true;
        description = "Repair works in progress…";
      }

      //8: suspended
      else if (status == 8) {
        actions.push({
          name: "Resume Job",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });

        if (
          !(
            this.jobDetail.depp_schedule != null &&
            this.jobDetail.depp_schedule.is_allied == 1 &&
            this.jobDetail.is_diagnosis_approved
          )
        ) {
          this.isDeclineQuoteEnable = true;
        }

        if (this.jobDetail.is_diagnosis_approved) {
          actions.push({
            name: "Approved Quote",
            originalImg: require("../../assets/images/approved-quote-icon.png"),
            hoverImg: require("../../assets/images/approved-qoute-hover-icon.png"),
          });
        }
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        this.is_QR = true;
        description =
          'This job is in progress but under suspended mode. Hit "Resume Job" to start working on it again!';
      }

      //9: completed
      else if (status == 9) {
        actions.push({
          name: "Approved Quote",
          originalImg: require("../../assets/images/approved-quote-icon.png"),
          hoverImg: require("../../assets/images/approved-qoute-hover-icon.png"),
        });
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        this.is_QR = true;
        description = "This job has been completed. Get ready for another job.";
      }

      //10: cancelled
      else if (status == 10) {
        description = "Job has been cancelled.";
      }

      //11: rejected
      // else if (status == 11) {

      // }

      //13: new_system
      else if (status == 13) {
        actions.push({
          name: "View Order",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        // actions.push("QR Code");
        this.is_QR = true;
      }

      //16 : diagnossis_fee_ppending
      else if (status == 16) {
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        // if (this.jobDetail.replacement_quote.is_consumer_requested_pricing) {
        //   actions.push("Installation Pricing");
        // }
        // actions.push("Job History");
        // actions.push("QR Code");
        this.is_QR = true;
        // isConsumerDeclined = true
        description = "";
      }

      //14:consumer_quote_decline
      else if (status == 14) {
        actions.push({
          name: "View Evaluation",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        actions.push({
          name: "End Job",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        actions.push({
          name: "Resubmit Quote",
          originalImg: require("../../assets/images/view-evaluation-icon.png"),
          hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
        });
        this.isConsumerQuoteDeclined = true;
        description = "Quote has been declined by consumer.";
      }

      // if (this.jobDetail.invoice_count > 0) {
      //   actions.push("View Invoice");
      // }


      //Check for edit evaluation
      if (this.jobDetail.actions.can_edit_diagnosis) {

        actions.splice(2, 0, {
            name: "Edit Evaluation",
            originalImg: require("../../assets/images/view-evaluation-icon.png"),
            hoverImg: require("../../assets/images/view-evaluation-hover-icon.png"),
          });
      
      }

      //Check for Decline Quote
      if (!this.jobDetail.actions.can_decline_job) {
           this.isDeclineQuoteEnable = false
      }


      //Remove share Quote button
      if (!this.jobDetail.actions.can_share_quote ?? true) {

        this.is_QR = false;

      }

      if (this.jobDetail.is_send_proposal == 1) {
        actions.push("Send Proposal");
      }

      if (type == "description") {
        return description;
      } else {
        return actions;
      }
    },

    getJobStatusDescription() {
      let description = "";
      let status = this.jobDetail.status;
      //1 : accepted
      if (status == 1) {
        description = "Time to check the unit and identify the fault.";
      }
      // 2: en_route
      else if (status == 2) {
        description = "Time to check the unit and identify the fault.";
      }
      //3 : arrived
      else if (status == 3) {
        description = "Time to check the unit and identify the fault.";
      }
      //4 :diagnosis_started
      else if (status == 4) {
        description =
          "Evaluation in progress. Create a quote for consumer's approval.";
      }
      //5 :diagnosis_completed
      else if (status == 5) {
        description =
          this.jobDetail.consumer.first_name +
          " is approving the quote. Look out for them; they may need your help.";
      }
      //6: quote_ready
      else if (status == 6) {
        description =
          "Congratulations! The customer has approved the quote. Start fixing the unit as per the approved quote.";
      }
      //7: started
      else if (status == 7) {
        description = "Repair works in progress…";
      }
      //8: suspended
      else if (status == 8) {
        description =
          'This job is in progress but under suspended mode. Hit "Resume Job" to start working on it again!';
      }
      //9: completed
      else if (status == 9) {
        description = "This job has been completed. Get ready for another job.";
      }
      //10: cancelled
      else if (status == 10) {
        description = "Job has been cancelled.";
      }
      //11: rejected
      // else if (status == 11) {
      // }
      //13: new_system
      // else if (status == 13) {
      // }
      //16 : diagnossis_fee_ppending
      else if (status == 16) {
        description = "";
      }
      //14:consumer_quote_decline
      else if (status == 14) {
        description = "Quote has been declined by consumer.";
      }
      return description;
    },

    actionClicked(action) {
      if (action == "Resume Evaluation") {
        this.$router.push({
          path: `/select/unit/${this.$route.params.id}`,
        });
      } else if (action == "Edit Evaluation") {
        this.storeChecks({
          booking_id: this.$route.params.id,
          diagnosis_type: "edit_diagnosis_items",
          diagnosis_data: JSON.stringify(null),
        });
      } else if (action == "View Evaluation") {
        this.techUrl = this.jobDetail.technician_diagnostic_url + "/?status=1";
        this.modal = new Modal(this.$refs.iframeModal);
        this.modal.show();
      } else if (action == "Approved Quote") {
        this.techUrl = this.jobDetail.technician_diagnostic_url + "/?status=0";
        this.modal = new Modal(this.$refs.iframeModal);
        this.modal.show();
      } else if (action == "Suspend Job") {
        this.$dialog.show({
          title: "Suspend Job",
          text: "Are you sure you want to suspend this job for now? You will be able to resume this job later.",
          onConfirm: () => {
            this.updateJobStatus({
              booking_id: this.$route.params.id,
              status: "8",
            });
          },
        });
      } else if (action == "Resume Job") {
        this.$dialog.show({
          title: "Resume Job",
          text: "Are you sure you want to resume this job?",
          onConfirm: () => {
            this.resumeJob({
              booking_id: this.$route.params.id,
            });
          },
        });
      } else if (action == "End Job") {
        if (this.jobDetail.status == 14) {
          this.declineCallButtonClicked();
        } else {
          this.$dialog.show({
            title: "End Job",
            text: "Are you sure you want to end this job?",
            onConfirm: () => {
              this.endJob({
                booking_id: this.$route.params.id,
              });
            },
          });
        }
      } else if (action == "Resubmit Quote") {
        this.$dialog.show({
          title: "Resubmit Quote",
          text: "Are you sure you want to resubmit this quote to consumer?",
          onConfirm: () => {
            this.resubmitQuote({
              booking_id: this.$route.params.id,
            });
          },
        });
      }
    },

    declineCallButtonClicked() {
      this.getMarkAsCompleteObject({
        booking_id: this.$route.params.id,
      });
      document.body.style.overflow = "hidden";
    },

    cancelBooking() {
      // Implement the cancellation logic here
      alert("Booking cancelled");
    },

    getCapitalize(str) {
      let res = "" + str;
      return res
        .toLowerCase()
        .split(" ")
        .map(function (i) {
          if (i.length > 2) {
            return i.charAt(0).toUpperCase() + i.substr(1);
          }

          return i;
        })
        .join(" ");
    },
  },
};
</script>

<style>
.cancelBookingOption .form-group [type="radio"]:checked+label,
.cancelBookingOption .form-group [type="radio"]:not(:checked)+label {
  padding-left: 2rem;
  padding-right: 0;
}

.cancelBookingOption .form-group [type="radio"]:checked+label:before,
.cancelBookingOption .form-group [type="radio"]:not(:checked)+label:before {
  left: 5px;
  width: 23px;
  height: 23px;
  top: -3px;
}

.cancelBookingOption .form-group [type="radio"]:checked+label:after,
.cancelBookingOption .form-group [type="radio"]:not(:checked)+label:after {
  left: 9px;
  top: 1px;
}

.cancelBookingOption .form-control {
  width: 150px;
  font-size: 18px;
  border-color: #092e87;
  height: 35px;
}

#iframeModal iframe {
  height: 75vh;
  width: 100%;
  overflow-x: hidden;
}

#iframeModal .closebutton,
#qrModal .closebutton,
#partnerModal .closebutton,
#profileModal .closebutton {
  filter: none;
  border: 0;
  opacity: 1;
}

#iframeModal .closebutton button,
#qrModal .closebutton button,
#partnerModal .closebutton button,
#profileModal .closebutton button {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0.5rem;
  opacity: 1;
}

.allActionButtons span {
  width: 49%;
}

.allActionButtons span button {
  display: block;
  width: 100%;
}

.booking .dropdown-menu li img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.booking .dropdown-menu {
  width: 180px;
}
</style>
