<template>
    <div class="pt-4">
        <AppointmentInfo v-if="isShowClaimDetails"></AppointmentInfo>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from "vuex";
import AppointmentInfo from "@/components/appointment/AppointmentInfo.vue";

export default {

    components: {
        AppointmentInfo,
    },

    data() {
        return {
            isShowClaimDetails: true,
        }
    },
    computed: {
        ...mapGetters([])
    },

    watch: {
    
    },

    mounted() {

    },

    methods: {
        ...mapActions(["setCommponent"]),

    
    }

}
</script>
