<template>
  <!-- <div v-if = "td.length > 0" id="layout">
    <FLoader />
    <HeaderPartial />
    <div class="main-content">
      <div class="container mt-3">
        <router-view />
        <MyDialog />
      </div>
    </div>
    <FooterPartial />
  </div> -->
  <div  >
    <FLoader />
    <nest_header v-if="isFromMobile != 1"/>
    <div class="main-content">
      <div class="container mt-3">
      <router-view />
      <MyDialog />
    </div>
  </div>
    <FooterPartial />
  </div>
</template>

<script>
import FooterPartial from "./footer/FooterPartial";
import nest_header from "../../components/nest_components/nest_header.vue";


export default {
  name: "Layout",

  computed: {
   

    isFromMobile() {
      return this.$route.query.is_from_mobile ?? 0;
    },
  },

  components: {
    FooterPartial,
    nest_header
  },

  data() {
    return {
      td : '',
    };
  },
   mounted() {
    
 
  },

  methods: {
  
  }
};
</script>
