<template>
  <div v-if="selectedItem">
    <h6 class="primaryBg whiteText rounded p-2 mb-2">Select Parts</h6>
    
    <div class="modalSearch">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          v-model="search"
          placeholder="Search Part"
        />
        <button class="p-0 border-0">
          <img src="../../assets/images/search-white.png" alt="" />
        </button>
      </div>
    </div>

    <div class="listCheckbox mt-2">
      <ul class="list-inline mb-0">
        <li v-for="(item, idx) in filteredList" :key="idx">
          <div class="checkbox-container">
            <input
              type="checkbox"
              :id="item.part_id + '-' + index"
              :checked="isChecked(item)"
              @change="toggleSelection(item)"
            />
            <label class="checkbox" :for="item.part_id + '-' + index">{{ item.part_name }}</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {  mapGetters } from "vuex";

export default {
  props: ["bookingParts", "item", "index"],
  data() {
    return {
      search: "",
      selectedItem: null,
    };
  },

  
  computed: {
    ...mapGetters([
      "bookingResponse"
    ]),


    filteredList() {
      return this.bookingParts.filter((item) => {
        return Object.keys(item).some((key) =>
          ("" + item[key]).toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    item: function () {
      this.search = ""
      this.selectedItem = this.item;
    },
  },
  methods: {

   
  mounted() {
  
    this.search  = ""
  },

    isChecked(item) {
      return this.selectedItem.parts.some(part => part.part_id === item.part_id);
    },
    toggleSelection(item) {

      if(this.bookingResponse.is_flat_price_model){
        this.selectedItem.parts = []
        this.selectedItem.parts.push(JSON.parse(JSON.stringify(item))); // Add item

      }else{
        const index = this.selectedItem.parts.findIndex(part => part.part_id === item.part_id);
      if (index > -1) {
        this.selectedItem.parts.splice(index, 1); // Remove item
      } else {
        this.selectedItem.parts.push(JSON.parse(JSON.stringify(item))); // Add item
      }
      }
      
    }
  },
};
</script>


<style>

</style>
