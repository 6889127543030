<template>
  <!-- equipmentSec start -->
  <div class="equipmentSec mt-5 mb-4">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="unitHead">
     
          <h3 class="unitInfo-heading">
            Enter or update the equipment information
          </h3>
          <div class="resetPart">
            <div @click="resetClicked()">
              <p class="resetBtn mb-0">
                Reset <img src="../../assets/images/reset-icon.png" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>Manufacturer</h3>
          <select v-model="selectedManufacture" class="form-control form-select form-select-sm" @change="validateField"
            aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select Manufacturer</option>
            <option v-for="(item, idx) in saveHvacConfig != null && saveHvacConfig.manufacturers" :key="idx"
              :value="item" :selected="selectedManufacture != null && item.id == selectedManufacture.id">
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>System Type</h3>
          <select v-model="selectedSystemType" class="form-control form-select form-select-sm" @change="validateField"
            aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select System Type</option>
            <option v-for="(item, idx) in saveHvacConfig != null && saveHvacConfig.system_types" :key="idx"
              :value="item" :selected="selectedSystemType != null && item.id == selectedSystemType.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>Product Type</h3>
          <select v-model="selectedProductType" class="form-control form-select form-select-sm" @change="validateField"
            :disabled="selectedSystemType == null" aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select Product Type</option>
            <option v-for="(item, idx) in selectedSystemType != null && selectedSystemType.product_types" :key="idx"
              :value="item" :selected="selectedProductType != null && item.id == selectedProductType.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
 
      <div class="col-md-3" v-if="selectedProductType == null || (selectedProductType != null && selectedProductType.slug != 'cooling')">
        <div class="unitForm">
          <h3>Heat Type</h3>
          <select v-model="selectedHeatType" class="form-control form-select form-select-sm" @change="validateField"
            :disabled="selectedProductType == null" aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select Heat Type</option>
            <option v-for="(item, idx) in selectedProductType != null && selectedProductType.heat_types" :key="idx"
              :value="item" :selected="selectedHeatType != null && item.id == selectedHeatType.id">
              {{ item.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>{{ getCapacityTitle() }}</h3>

          <select v-model="selectedCapacity" class="form-control form-select form-select-sm" @change="validateField"
            :disabled="selectedProductType == null" aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select {{getCapacityTitle()}}</option>
            <option v-for="(item, idx) in getCapacities()" :key="idx" :value="item"
              :selected="selectedCapacity != null && item.id == selectedCapacity.id">
              {{ getCapacityName(item) }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>Placement</h3>
          <select v-model="selectedPlacement" class="form-control form-select form-select-sm" @change="validateField"
            :disabled="selectedSystemType == null" aria-label=".form-select-sm example">
            <option value="null" selected disabled>Select Placement</option>
            <option v-for="(item, idx) in selectedSystemType != null && selectedSystemType.placements" :key="idx"
              :value="item" :selected="selectedPlacement != null && item.id == selectedPlacement.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3" v-if="checkSeer()">
        <div class="unitForm">
          <h3>Seer Rating</h3>
          <input v-model="seer" v-on:keyup="validateField" type="text" placeholder="Enter Seer" class="form-control" @input="validateInput(idx, true)" :step="0.9" @paste="disablePaste" @keypress="isNumber($event)" />
        </div>
      </div>

      <div class="col-md-3" v-if="checkAfue()">
        <div class="unitForm">
          <h3>AFUE</h3>
          <input v-model="afue" v-on:keyup="validateField" type="text" placeholder="Enter afue" class="form-control"  @input="validateInput(idx, true)" :step="0.9" @paste="disablePaste" @keypress="isNumber($event)" />
        </div>
      </div>

      <div class="col-md-3" v-if="checkHspf()">
        <div class="unitForm">
          <h3>HSPF</h3>
          <input v-model="hspf" v-on:keyup="validateField" type="text" placeholder="Enter hspf" class="form-control" @input="validateInput(idx, true)" :step="0.9" @paste="disablePaste" @keypress="isNumber($event)"/>
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>Model Number</h3>
          <input v-model="model" v-on:keyup="validateField" type="text" placeholder="Enter model" class="form-control"
            :disabled="(saveHvacEquipmentInfo != null && saveHvacEquipmentInfo.model != null && saveHvacEquipmentInfo.model.is_disable == 1)" />
        </div>
      </div>

      <div class="col-md-3">
        <div class="unitForm">
          <h3>Serial Number</h3>
          <input v-model="serial" v-on:keyup="validateField" type="text" placeholder="Enter serial"
            :disabled="(saveHvacEquipmentInfo != null && saveHvacEquipmentInfo.serial != null && saveHvacEquipmentInfo.serial.is_disable == 1)"
            class="form-control" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="unitForm">
          <h3>Age / Installation Date</h3>
          <date-picker input-class="disable-input" format="MM/DD/YYYY" :placeholder="'Select Date'" :confirm="true"
            :readonly="false" v-bind:clearable="false" @change="validateField" :disabled-date="disabledBefore"
            valueType="format" class="form-control" v-model:value="manufacture_date">
          </date-picker>
        </div>
      </div>

    </div>
  </div>
  <div class="container">
    <div class="text-center mt-3">
      <button :disabled="!btnEnable" @click="storeEquipmentInfoClicked()" class="btn main-button">
        {{ this.$route.query.is_edit == 1 ? "Continue" : "Continue Editing" }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      btnEnable: false,
      selectedManufacture: null,
      selectedSystemType: null,
      selectedProductType: null,
      selectedHeatType: null,
      selectedPlacement: null,
      selectedCapacity: null,
      isPresetSystemSet: false,
      isPresetProductSet: false,
      isPresetHeatSet: false,
      seer: null,
      afue: null,
      hspf: null,
      model: null,
      serial: null,
      manufacture_date: null
    };
  },
  computed: {
    ...mapGetters([
      "saveHvacEquipmentInfo",
      "saveHvacConfig",
      "quoteDetail",
      "saveHvacEquipmentInfoStore",
    ]),
  },

  watch: {
    saveHvacEquipmentInfo: function () {
      this.getHvacConfig({
        manual_quote_id: this.quoteDetail.id,
      });
    },

    saveHvacConfig: function () {
      this.setExistingData()
    },

    saveHvacEquipmentInfoStore: function () {
      toast.success("Equipment information updated successfully.");
      this.setCommponent("QuoteItems");
    },

    selectedSystemType: {
      handler: function () {
        if (!this.isPresetSystemSet) {
          this.selectedProductType = null
          this.selectedCapacity = null
          this.selectedPlacement = null
          this.selectedHeatType = null
        } else {
          this.isPresetSystemSet = false
        }
      }
    },

    selectedProductType: {
      handler: function () {
        if (!this.isPresetProductSet) {
          this.selectedCapacity = null
          this.selectedHeatType = null
          this.seer = null
        } else {
          this.isPresetProductSet = false
        }
      }
    },

    selectedHeatType: {
      handler: function () {
        if (!this.isPresetHeatSet) {
          this.afue = null
          this.hspf = null
        } else {
          this.isPresetHeatSet = false
        }
      }
    },

  },

  setUp() { },
  mounted() {
    this.getHvacEquipmentInfo({
      quote_id: this.quoteDetail.id,
    });
  },

  methods: {
    ...mapActions(["getHvacEquipmentInfo", "getHvacConfig", "HvacEquipmentStore", "setCommponent"]),

    resetClicked() {
      this.setExistingData()
    },

    disabledBefore: function (date) {
      const cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      return date > cdate;
    },

    getCapacities() {
      if (this.selectedProductType != null) {
        if (this.selectedProductType.slug == "heating") {
          return this.saveHvacConfig.btus
        } else {
          return this.saveHvacConfig.tonnages
        }
      } else { return [] }
    },

    getCapacityTitle() {
      if (this.selectedProductType != null) {
        if (this.selectedProductType.slug == "heating") {
          return 'Heating BTUs'
        } else {
          return 'Tonnage'
        }
      } else {return 'Tonnage'}
    },

    getCapacityName(data) {
      if (this.selectedProductType != null) {
        if (this.selectedProductType.slug == "heating") {
          return data.btu
        } else {
          return data.subtitle
        }
      } else ''
    },

    checkSeer() {
      return (this.selectedProductType != null && this.selectedProductType.slug != "heating")
    },

    checkAfue() {
      return (this.selectedHeatType != null &&
        (this.selectedHeatType.slug == "gas" ||
          this.selectedHeatType.slug == "boiler" ||
          this.selectedHeatType.slug == "dual_fuel"))
    },

    checkHspf() {
      return (this.selectedHeatType != null &&
        (this.selectedHeatType.slug == "heat_pump" ||
          this.selectedHeatType.slug == "dual_fuel"))
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber: function (evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var value = evt.target.value;
        if (value.length > 4) {
          return evt.preventDefault();
        } else {
          var dotcontains = value.indexOf(".") != -1;
          if (dotcontains)
            if (charCode == 46) return evt.preventDefault();
          if (charCode == 46) return true;
          if (charCode > 31 && (charCode < 48 || charCode > 57))
            return evt.preventDefault();
        }
        return true;
    },


    validateInput(idx, isPartPrice) {
            let regex = /^\d{0,4}(\.\d{0,2})?$/;
            if (isPartPrice) {
                if (!regex.test(this.manualItems[idx].part_price)) {
                    this.manualItems[idx].part_price = this.manualItems[idx].part_price.slice(0, -1);
                }
            } else {
                if (!regex.test(this.manualItems[idx].service.price)) {
                    this.manualItems[idx].service.price = this.manualItems[idx].service.price.slice(0, -1);
                }
            }
        },

    validateField() {
      if (this.isValid("watcher")) {
        this.btnEnable = true;
      } else {
        this.btnEnable = false;
      }
    },

    isEmpty(data) {
      if (data == undefined) {
        return true
      } else if (data.length > 0) {
        return false
      } else {
        return true
      }

    },

    isValid(action) {
      let status = true;
      let message = "";
      if (this.selectedManufacture == null) {
        status = false;
        message = "Manufacturer field is required.";
      } else if (this.selectedSystemType == null) {
        status = false;
        message = "System Type field is required.";
      } else if (this.selectedProductType == null) {
        status = false;
        message = "Product Type field is required.";
      } else if (this.selectedProductType.slug != 'cooling' && this.selectedHeatType == null) {
        status = false;
        message = "Heat Type field is required.";
      } else if (this.selectedCapacity == null) {
        status = false;
        message = this.getCapacityTitle()+" field is required.";
      } else if (this.selectedPlacement == null) {
        status = false;
        message = "Placement field is required.";
      } else if (this.checkSeer() && this.isEmpty(this.seer)) {
        status = false;
        message = "Seer field is required.";
      } else if (this.checkAfue() && this.isEmpty(this.afue)) {
        status = false;
        message = "Afue field is required.";
      } else if (this.checkHspf() && this.isEmpty(this.hspf)) {
        status = false;
        message = "Hspf field is required.";
      } else if (this.isEmpty(this.model)) {
        status = false;
        message = "Model Number field is required.";
      } else if (this.isEmpty(this.serial)) {
        status = false;
        message = "Serial Number field is required.";
      } else if (this.isEmpty(this.manufacture_date)){
        status = false;
        message = "Installation Date field is required.";
      }
      if (!status && action == "submit") {
        toast.error(message);
      }

      return status;
    },

    setExistingData() {
      if (this.saveHvacConfig != null) {
        let self = this
        this.selectedManufacture = this.saveHvacConfig.manufacturers.filter(function (item) {
          return (self.saveHvacEquipmentInfo.manufacturer !=null && item.id == self.saveHvacEquipmentInfo.manufacturer.id)
        })[0] ?? null

        this.isPresetSystemSet = true
        this.selectedSystemType = this.saveHvacConfig.system_types.filter(function (item) {
          return (self.saveHvacEquipmentInfo.system_type !=null && item.id == self.saveHvacEquipmentInfo.system_type.id)
        })[0] ?? null

        this.isPresetProductSet = true
        if (this.selectedSystemType != null) {
          this.selectedProductType = this.selectedSystemType.product_types.filter(function (item) {
            return (self.saveHvacEquipmentInfo.product_type != null && item.id == self.saveHvacEquipmentInfo.product_type.id)
          })[0] ?? null

          this.selectedPlacement = this.selectedSystemType.placements.filter(function (item) {
            return (self.saveHvacEquipmentInfo.placement != null && item.id == self.saveHvacEquipmentInfo.placement.id)
          })[0] ?? null

        } else {
          this.selectedProductType = null
          this.selectedPlacement = null
        }
        
        this.isPresetHeatSet = true
        if (this.selectedProductType != null) {
          if (this.selectedProductType.slug != "cooling") {
            this.selectedHeatType = this.selectedProductType.heat_types.filter(function (item) {
              return (self.saveHvacEquipmentInfo.heat_type != null && item.id == self.saveHvacEquipmentInfo.heat_type.id)
            })[0] ?? null
          }

          if (this.selectedProductType.slug == "heating") {
            this.selectedCapacity = this.saveHvacConfig.btus.filter(function (item) {
              return (self.saveHvacEquipmentInfo.heating_btu != null && item.id == self.saveHvacEquipmentInfo.heating_btu.id)
            })[0] ?? null
          } else {
            this.selectedCapacity = this.saveHvacConfig.tonnages.filter(function (item) {
              return (self.saveHvacEquipmentInfo.tonnage != null && item.id == self.saveHvacEquipmentInfo.tonnage.id)
            })[0] ?? null
          }
        } else {
          this.selectedHeatType = null
          this.selectedCapacity = null
        }

        this.seer = this.saveHvacEquipmentInfo.seer
        this.afue = this.saveHvacEquipmentInfo.afue
        this.hspf = this.saveHvacEquipmentInfo.hspf

        if(this.saveHvacEquipmentInfo.serial!=null){
          this.serial = this.saveHvacEquipmentInfo.serial.text
        }
        if(this.saveHvacEquipmentInfo.model!=null){
          this.model = this.saveHvacEquipmentInfo.model.text
        }
        if(this.saveHvacEquipmentInfo.manufacture_date.length > 0){
          this.manufacture_date = this.saveHvacEquipmentInfo.manufacture_date
        } else {
          this.manufacture_date = null
        }
        
        this.validateField()
      } else {
        this.getHvacConfig({
          manual_quote_id: this.quoteDetail.id,
        });
      }
    },

    storeEquipmentInfoClicked() {
      this.$dialog.show({
        title: "Equipment Information",
        text: "Are you sure you want to update equipment information?",
        onConfirm: () => {
          if (this.isValid("submit")) {
            this.storeCall()
          }
        },
      });
    },

    storeCall() {


      let unit = {
        manufacturer_id: this.selectedManufacture.id,
        type: this.selectedSystemType.name,
        system_type_id: this.selectedSystemType.id,
        product_type_id: this.selectedProductType.id,
        location_id: this.selectedPlacement.id,
        serial: this.serial,
        model: this.model,
        manufacture_date: this.manufacture_date
      }

      if (this.selectedProductType.slug != 'cooling'){
        unit.heat_type_id = this.selectedHeatType.id
      }

      if(this.selectedProductType.slug == 'heating'){
        unit.heating_btu_id = this.selectedCapacity.id
      } else {
        unit.tonnage_id = this.selectedCapacity.id
      }

      if(!this.isEmpty(this.saveHvacEquipmentInfo.unit_id)){
        unit.id = this.saveHvacEquipmentInfo.unit_id
      }

      if(this.checkSeer()){
        unit.seer = this.seer
      }

      if(this.checkHspf()){
        unit.hspf = this.hspf
      }

      if(this.checkAfue()){
        unit.afue = this.afue
      }

      let payload = {
        quote_id: this.quoteDetail.id,
        units: JSON.stringify([unit])
      }
      this.HvacEquipmentStore(payload)
    }

  },
};
</script>

<style>
.disable-input {
  pointer-events: none;
}

.unitHead {
  position: relative;
  margin-bottom: 3rem;
}

.unitForm {
  border-radius: 6px;
  margin-bottom: 20px;
}

.unitForm h3 {
  font-size: 17px;
  color: #092e87;
  font-weight: normal;
}

.resetPart {
  position: absolute;
  top: -4px;
  right: 0;
}

.resetPart img {
  width: 40px;
  margin-left: 0.5rem;
}

.unitForm h3 span {
  color: #fe5000;
}

.unitForm .form-control {
  height: 45px;
  border-color: #cacfd3;
}

.unitForm .form-control:focus {
  box-shadow: none;
}

.unitForm .mx-input-wrapper {
  position: relative;
  top: -5px;
}

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}

.main-button:active {
  background-color: #092e87;
  color: #fff;
}

.main-button:disabled {
  background-color: #ccc;
}

.unitInfo-heading {
  font-size: 26px;
  font-weight: 500;
  color: #092e87;
  text-align: center;
}

.unitForm .mx-datepicker {
  width: 100%;
}

.unitForm .mx-datepicker input {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  border: none;
  padding: 0 0 0 12px;
}

.mx-icon-calendar {
  color: #fe5000;
}

.resetBtn {
  color: #fe5000;
  font-weight: 600;
  cursor: pointer;
}

.resetBtn:active {
  font-weight: 600;
  cursor: pointer;
  color: #092e87;
}
</style>
