<template>
  <main class="serviceRepairHistoryPg">
    <div class="container">
      <div class="heading">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          Service & Repair History
        </h4>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-5">
        <ul class="d-flex list-inline mb-0">
          <li><a href="/dashboard" class="greyText">My Account</a></li>
          <li class="blueText">Service & Repair History</li>
        </ul>
      </div>
      <div v-if="inProgressJobs.length > 0" class="progressTable border-bottom pb-4 mb-4">
        <nest_table @event="onJobClicked" :data="inProgressJobs" type="inprogress"/>
        <nest_pagination />
      </div>
      <div v-if="completedJobs.length > 0" class="completeTable">
        <nest_table @event="onJobClicked"  :data="completedJobs" type="completed"/>
        <nest_pagination />
      </div>
    </div>
  </main>
</template>
<script>
import nest_table from "../../components/nest_components/nest_table.vue";
// import nest_pagination from "../../components/nest_components/nest_pagination.vue";

import {
    mapActions,
    mapGetters
} from "vuex";



export default {
  data() {
        return {

          limit : 10,
          page :0

        };
    },

  components: {
    nest_table,
  },
  computed: {
        ...mapGetters(["inProgressJobs" , "completedJobs"]),

  },
  mounted() {

    this.getInProgressBooking()
    this.getCompletedBooking()
  },
  methods: {
        ...mapActions(["getBookingList"]),

        getInProgressBooking() {

          let params = {
            status : '8',
            limit : this.limit,
            page : this.page
          }
            this.getBookingList(params);
        },

        getCompletedBooking(){

          let params = {
            status : '9',
            limit : this.limit,
            page : this.page
          }
            this.getBookingList(params);
        },

        onJobClicked(item){

          this.$router.push({
                    path: `/job/detail/${item.id}`
                });
        }

    },

};
</script>
