<template>
  <!-- consumerHead start -->
  <div class="consumerHead py-2">
    <p class="mb-0 secondaryColor">
      Claim ID:
      <span class="primaryColor">
        {{ quoteDetail.partner_info.job_number }}</span
      >
    </p>
    <p class="mb-0 secondaryColor">
      Consumer Name:
      <span class="primaryColor">
        {{ quoteDetail.consumer.full_name }}
      </span>
    </p>
  </div>
  <!-- notesSec start -->
  <div class="notesSec mb-5">
    <div
      class="notesHead bgPrimary p-3 rounded d-flex justify-content-between align-items-center"
    >
      <h2 class="mb-0 whiteColor lh-sm fw-bold">Notes</h2>
      <a class="secondaryButton d-flex align-items-center" @click="addNotesBtn">
        Add Notes
        <img src="../../assets/images/add-circle.png" alt="" class="ms-2" />
      </a>
    </div>
    <div class="notesMain">
      <!-- <ul class="notesList list-inline mb-0"> -->
      <ul
        :class="
          notesData != null && notesData.length > 4
            ? 'notesList list-inline mb-0'
            : 'primaryList list-inline mb-0'
        "
      >
        <li v-for="(item, index) in notesData" :key="index" class="p-3">
          <p class="mb-0 lh-sm">
            {{ convertNotesTimsStampToDate(item.created_at) }}
          </p>
          <p class="mb-0 lh-sm">{{ item.notes }}</p>
        </li>
      </ul>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade modal-lg"
    ref="addNotes"
    id="exampleModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <NoteModal></NoteModal>
    </div>
  </div>
  <!-- <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="consumer-notes">
          <h2>
            Claim ID: {{ quoteDetail.partner_info.job_number }}<br />
            Consumer Name: {{ quoteDetail.consumer.full_name }}
          </h2>
          <div class="notesHeading2">
            <h3>Notes</h3>

            <u @click="addNotesBtn" class="addNotes-link">Add Notes</u>
          </div>
          <div class="notes-text">
            <p v-for="(item, index) in notesData" :key="index">
              {{ convertNotesTimsStampToDate(item.created_at) }}: <br />{{
                item.notes
              }}
            </p>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import NoteModal from "@/components/notes_modal/NotesModal.vue";

import { Modal } from "bootstrap";

export default {
  components: {
    NoteModal,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["quoteDetail", "notesData", "apptDetail"]),
  },

  watch: {},

  mounted() {
    this.getNotesData();
  },

  methods: {
    ...mapActions(["getNotes"]),

    getNotesData() {
      this.getNotes({
        quote_id: this.quoteDetail.id,
      });
    },
    convertNotesTimsStampToDate(val) {
      return moment.unix(val).format("hh:mm A - MM/DD/YYYY");
    },

    addNotesBtn() {
      this.modal = new Modal(this.$refs.addNotes);
      this.modal.show();
    },
  },
};
</script>

<style>
.whiteColor {
  color: #fff;
}
.primaryColor {
  color: #092e87;
}
.secondaryColor {
  color: #fe5000;
}
.bgPrimary {
  background: #092e87;
}
.bgSecondary {
  background: #fe5000;
}
.primaryButton {
  background: #092e87;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}
.secondaryButton {
  background: #fe5000;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
}
.notesHead h2 {
  font-size: 20px;
}
.notesMain {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}
.notesList {
  height: 280px;
  overflow-y: scroll;
}
.primaryList {
  height: auto;
}
.notesList::-webkit-scrollbar {
  width: 5px;
}
.notesList::-webkit-scrollbar-track {
  border-radius: 10px;
}
.notesList::-webkit-scrollbar-thumb {
  background: #fe5000;
  border-radius: 10px;
}
.notesList li:nth-child(even),
.primaryList li:nth-child(even) {
  background: #f2f2f2;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .secondaryButton {
    padding: 0.3rem 0.5rem;
    font-size: 15px;
  }
  .notesHead {
    padding: 0.5rem !important;
  }
}
/* .consumer-notes {
  padding: 15px 0;
  background: #f8f8f8;
  border: 1px solid #747171cf;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 0;
  overflow: auto;
}

.consumer-notes h2 {
  color: #fff;
  background: #092e87;
  font-size: 18px;
  font-weight: normal;
  padding: 10px 10px;
  margin: 0;
}

.notes-text {
  max-height: 150px;
  overflow-y: scroll;
  padding: 0 10px;
}

.consumer-notes p {
  color: #3c3c3c;
  font-size: 18px;
  margin-top: 15px;
}

.addNotes-link {
  font-weight: bold;
  color: #fe5000;
  font-size: 16px;
  text-align: right;
  display: inline-block;
  cursor: pointer;
  margin: 10px;
}

.notesHeading h3 {
  background: #3c3c3c;
  color: #fff;
  padding: 0px 10px;
  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
}

.notesHeading2 {
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
}

.notesHeading2 h3 {
  color: #092e87;
  padding-right: 5px;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  border-bottom: 2px solid #ff5b01;
  display: inline-block;
} */
.notesHead .secondaryButton img {
  width: 17px;
  height: 17px;
}
</style>
