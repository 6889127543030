<template>
  <div class="nestNotesModal">
    <h6 class="mb-0 d-flex align-items-center" @click="openNotesModal">
      <span class="me-2 blackText">Notes</span>
      <img src="../../assets/images/notes-icon.png" alt="" />
    </h6>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="notesModal"
    ref="notesModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body p-2">
          <!-- <div class="closebutton d-flex justify-content-end">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div> -->
          <div
            class="notesHead d-flex justify-content-between align-items-center primaryBg rounded p-2"
          >
            <h5 class="mb-0 whiteText fw-bold">Notes</h5>
            <div class="closebutton d-flex justify-content-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modalCntnt">
            <nest_notes v-if="bookingNotes" :isFromJobDetail="false"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import nest_notes from "./nest_notes.vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
 
  components: {
    nest_notes,
  },

  computed: {
    ...mapGetters(["bookingNotes"]),
  },
  mounted() {},

  data() {
    return {
      isGetNoteApiCalled: true,
    };
  },

  watch: {
    bookingNotes: function () {
      if (this.modal == null) {
        // if (this.bookingNotes && this.bookingNotes.length > 0) {
          this.modal = new Modal(this.$refs.notesModal);
          this.modal.show();
        // } else {
        //   toast.error("No notes Found.");
        // }
      }
    },
  },

  methods: {
    ...mapActions(["delete", "diagnosisSubmit", "getBookingNotes"]),

    openNotesModal() {
      this.modal = null;
      this.getBookingNotes({
        booking_id: this.$route.params.id,
      });
    },
  },
};
</script>

<style>
.nestNotesModal {
  cursor: pointer;
}
.nestNotesModal img {
  width: 40px;
}
#notesModal .btn-close {
  filter: invert(1);
  opacity: 1 !important;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 0.5rem;
  border-width: 2px;
  font-size: 13px;
}
</style>
