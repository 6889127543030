<template>
  <div v-if="visible">
    <div
      class="modal fade modal-md show"
      id="modalDiscard"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display: block; background: #00000050"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content discard-wrap">
          <div class="modal-header p-0">
            <button
              @click="hide"
              type="button"
              class="btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="@/assets/images/modal-cross.png" />
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="contPopup text-center">
              <!-- <img src="../../assets/images/alert-modal-icon.png" alt="slert-icon" class="alertIcon"> -->
              <h3 class="mb-0 orangeText fw-bold">{{ title }}</h3>
              <!-- <img v-if="image!=null" src="@/assets/images/pop-req-img.png" class="my-4"> -->
              <img
                v-if="image != null"
                :src="require(`@/assets/images/${image}`)"
                class="my-4"
              />
              <p class="my-4">{{ text }}</p>
              <div class="text-center">
                <button class="modalBtn buttonSecondary text-uppercase" @click="confirm"
                  >Continue</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/plugins/EventBus";
export default {
  data() {
    return {
      visible: false,
      title: "",
      text: "",
      image: null,
      // adding callback function variable
      onConfirm: {},
    };
  },

  mounted() {
    EventBus.on("show", (params) => {
      this.show(params);
    });
  },

  methods: {
    hide() {
      // this method is unchanged
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === "function") {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      this.title = params.title;
      this.text = params.text;
      this.image = params.image;
      // setting callback function
      this.onConfirm = params.onConfirm;
    },
  },
};
</script>

<style>
.modals-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modals {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.discard-wrap {
  /* border-radius: 20px;  */
  box-shadow: 0 0 10px #fff;
  border: 0;
  padding: 2rem;
}

.discard-wrap .modal-header {
  border: none;
}

.continue-popup .modal-header .close {
  position: absolute;
  top: 30px;
  right: 30px;
  background: #ff5b01;
  opacity: 1;
  padding: 0;
  height: 27px;
  width: 27px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0 2px 3px #444;
  font-weight: 100;
}

.continue-popup .modal-header {
  padding: 0;
  border: none;
}

.continue-popup {
  border-radius: 12px;
  box-shadow: 0 0 10px #fff;
  border: none;
}

.contPopup h1 {
  color: #003087;
  font-size: 35px;
  font-weight: 600;
  text-shadow: 0 1px 3px #666;
  margin-top: -30px;
}

.contPopup p {
  color: #444;
  font-size: 20px;
}

.btnClose {
  position: absolute;
  background: none;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  border-radius: 50px;
  z-index: 9;
  font-weight: bold;
  z-index: 9999;
}
.btnClose img {
  width: 40px;
}
.modalBtn{
  padding: 0.6rem 1.5rem !important;
}

@media screen and (max-width: 767px) {
}
</style>
