<template>
  <main>
  </main>
</template>

<script>


export default {

  components: {
   
  },

  computed: {

  },

  data() {
    return {

    };
  },

  mounted(){
   
  },

  methods: {
   
  },
};
</script>

<style></style>
