<template>
<header class="d-flex justify-content-between align-items-center">
    <nav class="navbar navbar-expand-lg navbar-light ">
        <div class="container-fluid">
            <a class="navbar-brand">
                <img src="../../assets/images/fyxifyLogo.png" alt="logo" class="img-fluid" />
            </a>
            <!-- <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li  @click=selectItem(item) v-for="(item, index) in data" :key="index" class="nav-item">
              <a  class="nav-link active" aria-current="page" href="#">{{item.title}}</a>
            </li>
          </ul>
          <div class="d-flex align-items-center justify-content-center">
            <div class="notification">
              <a href="#">
                <img
                  src="../../assets/images/bell-icon.png"
                  alt="bell-icon"
                  class="bellIcon"
                />
                <img
                  src="../../assets/images/notification-bell-icon.png"
                  alt="notification-bell-icon"
                  class="notificationBellIcon"
                />
              </a>
            </div>
            <div class="profile" @click="toggleMenu">
              <div class="user">
                <p class="whiteText mb-0">{{ user.user.full_name }}</p>
              </div>
              <div class="img-box">
                <img
                  :src="user.user.profile_picture"
                  alt="some user image"
                  class="w-100"
                />
              </div>
              <div :class="['menu', { active: isMenuActive }]">
                <ul class="list-inline mb-0 p-0">
                  <li>
                    <a href="#"
                      ><img
                        src="../../assets/images/edit-icon.png"
                        class="me-2"
                        alt="edit-profile-icon"
                      />Edit Profile</a
                    >
                  </li>
                  <li>
                    <a @click="logoutClicked()" type="submit"
                      ><img
                        src="../../assets/images/logout-icon.png"
                        class="me-2"
                        alt="edit-profile-icon"
                      />Logout</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </nav>
    <div v-if="!setCompanyInfo" class="companyLogo">
      <a>
        <img :src="saveCompanyInfo.company_logo" alt="logo" class="img-fluid" />
      </a>
    </div>
  </header>
</template>

<script>
import {
    mapGetters,mapActions
} from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["setCompanyInfo", "saveCompanyInfo"])
  },

  watch: {},

  mounted() {
    this.getData()
  },

  methods: {

    ...mapActions(["getCompanyInfo"]),

    getData() {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user != null) {
        this.getCompanyInfo({

        });
      }
    },

  }
};
</script>

<style>
/* .companyLogo {
    width: 150px;
    height: 100px;
    padding: 1rem !important;
    top: 20px;
    position: relative;
}
.companyLogo img {
    width: 150px !important;
    height: 70px;
    -o-object-fit: contain;
    object-fit: contain;
} */
.companyLogo {
    width: 150px;
    height: 50px;
    right: 10px;
    position: relative;
    padding: 0 !important;
}

.companyLogo img {
    width: 150px !important;
    height: 50px;
    -o-object-fit: contain;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
