<template>
  <div v-if="!getAppointmentDetails">
    <main class="claimPg">
      <div class="secHead d-flex align-items-center justify-content-between pb-3 mb-3">
        <h4 class="mb-0 primaryColor fw-bold">Claim Details:</h4>
        <div class="btn-group d-block d-md-none" v-for="(action, index) in actionButtons" :key="index">
          <button @click="actionButtonClicked(action)" class="btn main-button m-2">
            {{ action }}
          </button>
        </div>
      </div>
      <div class="secMain">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Appointment Title :</label>
              <p class="mb-0">{{ apptDetail.title }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Appointment ID :</label>
              <p class="mb-0">{{ apptDetail.id }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-if="apptDetail.manual_quote.id > 0">
            <div class="form-group">
              <label>Job ID :</label>
              <p class="mb-0">{{ apptDetail.manual_quote.id }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Consumer Name :</label>
              <p class="mb-0">{{ apptDetail.consumer.full_name }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Phone Number :</label>
              <p class="mb-0">
                <a :href="'tel:' + apptDetail.consumer.phone">
                  {{ apptDetail.consumer.phone }}
                </a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Visit Schedule :</label>
              <p class="mb-0">
                {{ convertTimsStampToDate(apptDetail.visit_date) }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Services Type :</label>
              <p class="mb-0">{{ apptDetail.type_text }} Service</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Trade Type :</label>
              <p class="mb-0">{{ apptDetail.trade_type }}</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Appointment Status : </label>
              <p class="mb-0 ms-1">
                <a style="
                    color: #ff5b01;
                    background: rgb(255 238 230);
                    padding: 0.2rem 0.5rem;
                    border-radius: 2px;
                  ">
                  {{ apptDetail.status_text }}
                </a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12" :class="apptDetail.flow_type == 'sar' ? 'd-block' : 'd-none'">
            <div class="form-group">
              <label>Source : </label>
              <img v-if="apptDetail.show_logo" :src="apptDetail.depp_schedule.company_logo" class="sourceLogo"
                alt="source-logo" />
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-12" :class="apptDetail.flow_type == 'sar' ? 'd-block' : 'd-none'">
            <div class="form-group">
              <label>Contact Support : </label>
              <p class="mb-0">
                <a :href="'tel:' + apptDetail.depp_schedule.phone"
                  class="blueText text-decoration-underline">{{ apptDetail.depp_schedule.phone }}</a>
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="row mb-3 ">
          <div class="col-12 text-center">
            <div
              class="btn-group"
              v-for="(action, index) in actionButtons"
              :key="index"
            >
              <button
                @click="actionButtonClicked(action)"
                class="btn main-button m-2"
              >
                {{ action }}
              </button>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="googleApi">
            <p>{{ apptDetail.address }}</p>
            <GMapMap :center="center" :zoom="16" map-type-id="terrain" style="width: 100%" class="locationMap" :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }">
              <GMapMarker v-for="(m, index) in markers" :key="index" :position="m.position" :clickable="true"
                :draggable="true" @click="center = m.position" />
            </GMapMap>

            <div class="clickSec d-md-flex justify-content-between">
              <p class="mb-0">
                <u class="underline clickBtn me-1" @click="openGoogleMaps()">Click here
                </u>
                to use Google Maps Navigation
              </p>
              <p class="mb-0" v-if="apptDetail">
                <!-- {{ apptDetail.depp_schedule.company_name }} -->
                <img v-if="apptDetail.show_logo" :src="apptDetail.depp_schedule.company_logo" class="ms-1" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <div class="row mt-4">
      <div class="col-md-6">
        <div class="userDetails">
          <h2>Claim Details :</h2>
          <ul class="detail-list">
            <li><span>Appointment Title :</span> {{ apptDetail.title }}</li>
            <li><span>Appointment ID :</span> {{ apptDetail.id }}</li>
            <li v-if="apptDetail.manual_quote.id > 0">
              <span>Job ID :</span> {{ apptDetail.manual_quote.id }}
            </li>
            <li>
              <span>Consumer Name :</span> {{ apptDetail.consumer.full_name }}
            </li>
            <li>
              <span>Phone Number :</span>
              <a :href="'tel:' + apptDetail.consumer.phone">
                {{ apptDetail.consumer.phone }}
              </a>
            </li>
            <li>
              <span>Visit Schedule :</span>
              {{ convertTimsStampToDate(apptDetail.visit_date) }}
            </li>
            <li><span>Services Type :</span> {{ apptDetail.type_text }}</li>
            <li><span>Trade Type :</span> {{ apptDetail.trade_type }}</li>
            <li>
              <span>Appointment Status :</span>
              <a class="text-orange">{{ apptDetail.status_text }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="location-map">
          <h2>{{ apptDetail.address }}</h2>
          <p class="googleLink googleLinkMob">
            <u class="underline" @click="openGoogleMaps()">Click here </u> to
            use Google Maps Navigation
          </p>
          <GMapMap
            :center="center"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%"
            class="locationMap"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }"
          >
            <GMapMarker
              v-for="(m, index) in markers"
              :key="index"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position"
            />
          </GMapMap>
        </div>
        <p class="googleLink">
          <u class="underline" @click="openGoogleMaps()">Click here </u> to use
          Google Maps Navigation
        </p>
      </div>
    </div> -->
    <!-- <h2 class="notes">Notes :</h2> -->
    <h4 v-if="apptDetail.notes.length > 0" class="mb-0 primaryColor fw-bold mb-1">
      Notes:
    </h4>
    <div v-if="apptDetail.notes.length > 0" class="row">
      <div class="col-12">
        <div class="notesBox">
          <p v-html="getNotesTxt(apptDetail.notes)">

          </p>
        </div>
      </div>
    </div>

    <div class="row d-none d-md-block">
      <div class="col-12 text-center">
        <div class="btn-group" v-for="(action, index) in actionButtons" :key="index">
          <button @click="actionButtonClicked(action)" class="btn main-button m-2">
            {{ action }}
          </button>
        </div>
      </div>
    </div>

    <div class="modal fade modal-lg" ref="tradecall" id="exampleModal" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <TradeCallDialog @event="TradeCallBack"></TradeCallDialog>
      </div>
    </div>

    <div class="modal fade modal-md" ref="serviceType" id="exampleModal" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <!-- <div class="modal-dialog modal-dialog-centered"> -->
      <ServiceTypeDialogue @event="serviceTypeCallBack"></ServiceTypeDialogue>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { useToast } from "vue-toastification";

const toast = useToast();
import TradeCallDialog from "@/components/tradecall_dialog/TradeCallDialog.vue";
import ServiceTypeDialogue from "@/components/ServiceTypeDialogue.vue";
import { Modal } from "bootstrap";

export default {
  components: {
    TradeCallDialog,
    ServiceTypeDialogue,
  },

  data() {
    return {
      isStartQuote: false,
      originlat: 0,
      originlng: 0,
      actionButtons: [],
      center: {
        lat: 0,
        lng: 0,
      },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        }, // Along list of clusters
      ],
    };
  },
  computed: {
    ...mapGetters([
      "cancelAppointment",
      "getAppointmentDetails",
      "apptDetail",
      "setAppointmentAction",
      "saveApptointmentActionEnroute",
      "saveApptointmentActionArrived",
      "saveApptointmentCreatelObject",
      "services",
      "bookingConverted",
    ]),
  },

  watch: {
    bookingConverted: function () {
      this.getAppointmenttDetails();
    },

    services: function () {
      this.modal = new Modal(this.$refs.serviceType);
      this.modal.show();
    },
    apptDetail: function () {
      let user = JSON.parse(localStorage.getItem('user'));
      let user_id = user.user.user_id;

      if (this.apptDetail.technician.user_id != user_id) {

        this.$router.push({
          name: 'NotFound',
        });

      } else {
        this.markers[0].position.lat = this.apptDetail.lat;
        this.markers[0].position.lng = this.apptDetail.lng;

        this.center.lat = this.apptDetail.lat;
        this.center.lng = this.apptDetail.lng;

        this.actionButtons = [];
        if (this.apptDetail.status == 0) {
          this.actionButtons.push("On My Way");
        } else if (this.apptDetail.status == 1) {
          this.actionButtons.push("Mark As Arrived");
        } else if (this.apptDetail.status == 2) {
          this.actionButtons.push("Start Quote");
        } else if (this.apptDetail.status == 7) {
          //cancel status
        } else {
          if (this.apptDetail.flow_type == "sar") {
            if (this.apptDetail.booking.status + "" == "4") {
              this.$router.push({
                path: `/select/unit/${this.apptDetail.booking.id}`,
                query: {
                  is_from_mobile:
                    Number(this.$route.query.is_from_mobile) === 1 ? 1 : 0,
                },
              });
            } else {
              this.$router.push({
                path: `/job/detail/${this.apptDetail.booking.id}`,
                query: {
                  is_from_mobile:
                    Number(this.$route.query.is_from_mobile) === 1 ? 1 : 0,
                },
              });
            }
          } else {
            if (this.isStartQuote) {
              this.$router.push({
                path: `/quote/detail/${this.apptDetail.manual_quote.id}`,
                query: {
                  is_edit: 1,
                },
              });
            } else {
              this.$router.push({
                path: `/quote/detail/${this.apptDetail.manual_quote.id}`,
              });
            }
          }
        }
      }
    },

    saveApptointmentActionEnroute: function () {
      toast.success("Appointment has been updated successfully.");
      this.getAppointmenttDetails();
    },

    saveApptointmentActionArrived: function () {
      toast.success("Appointment has been updated successfully.");
      this.getAppointmenttDetails();
    },

    saveApptointmentCreatelObject: function () {
      this.getAppointmenttDetails();
    },
    cancelAppointment: function () {
      toast.success("Appointment has been updated successfully.");
      this.getAppointmenttDetails();
    },
  },

  mounted() {
    this.getAppointmenttDetails();
  },

  methods: {
    ...mapActions([
      "appointmentCancel",
      "getAppointmentDetailsAPICall",
      "markAsEnrouteAPICall",
      "markAsArrivedAPICall",
      "appointmentCreate",
      "setCommponent",
      "getServices",
      "convretToBooking",
    ]),


    getNotesTxt(note) {

      return note.replace("\n", "<br>")
    },
    getAppointmenttDetails() {
      this.getAppointmentDetailsAPICall({
        id: this.$route.params.id,
      });
    },

    markAsEnroute() {
      this.$dialog.show({
        title: "Status Change",
        text: "Are you sure you want to mark yourself enroute?",
        // image: "pop-req-img.png",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.markAsEnrouteAPICall({
            appointment_id: this.$route.params.id,
          });
        },
      });
    },

    markAsArrived() {
      this.$dialog.show({
        title: "Status Change",
        text: "Are you sure you want to mark yourself as Arrived?",
        // image: "pop-req-img.png",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.markAsArrivedAPICall({
            appointment_id: this.$route.params.id,
          });
        },
      });
    },

    TradeCallBack(data) {
      if (data == "close") {
        this.modal.hide();
      } else if (data == "confirm") {
        this.modal.hide();
        this.isStartQuote = true;
        this.appointmentCreate({
          appointment_id: this.$route.params.id,
        });
      }
    },

    startQuote() {
      if (this.apptDetail.depp_schedule.is_tradecall_fee_applicable == 1) {
        this.modal = new Modal(this.$refs.tradecall);
        this.modal.show();
      } else {
        this.$dialog.show({
          title: "Status Change",
          text: "Are you sure you want to start this quote?",
          // image: "pop-req-img.png",
          // we are passing callback method for our confirm button
          onConfirm: () => {
            this.isStartQuote = true;
            this.appointmentCreate({
              appointment_id: this.$route.params.id,
            });
          },
        });
      }
    },

    convertTimsStampToDate(val) {
      return moment.unix(val).format("MM/DD/YYYY - hh:mm A");
    },

    convertNotesTimsStampToDate(val) {
      return moment.unix(val).format("hh:mm a MM/DD/YYYY");
    },

    actionButtonClicked(action) {
      if (action == "On My Way") {
        this.markAsEnroute();
      } else if (action == "Mark As Arrived") {
        this.markAsArrived();
      } else if (action == "Start Quote") {
        if (this.apptDetail.flow_type == "sar") {
          this.convertToBooking();
        } else {
          this.startQuote();
        }
      }
    },

    convertToBooking() {
      this.convertToBookingAPICall(this.apptDetail.service_id);

      // if (this.apptDetail.depp_schedule.status) {
      //     if (this.apptDetail.depp_schedule.is_allied) {
      //         if (this.apptDetail.service_id == 9) {
      //             //convert to boooing api calll
      //             this.convertToBookingAPICall('9')
      //         } else {
      //             this.getServices();
      //         }
      //     } else {
      //         this.getServices();

      //     }
      // } else {
      //     this.getServices();
      // }
    },

    serviceTypeCallBack(data, sType) {
      if (data == "close") {
        this.modal.hide();
      } else if (data == "confirm") {
        this.modal.hide();
        this.convertToBookingAPICall(sType);
      }
    },

    convertToBookingAPICall(sType) {
      //convretToBooking

      this.convretToBooking({
        appointment_id: this.$route.params.id,
        service_id: sType,
      });
    },

    openGoogleMaps() {
      const desLat = this.apptDetail.lat ?? 0;
      const desLng = this.apptDetail.lng ?? 0;
      const googleMapsUrl = `https://maps.google.com/?q=${desLat},${desLng}`;

      // Check if the Google Maps app is installed
      if (navigator.platform.indexOf("Win") > -1) {
        // Windows platform, open in a new window
        window.open(googleMapsUrl, "_blank");
      } else {
        // Non-Windows platform, try to open in the maps app
        window.location.href = googleMapsUrl;
        //  window.location.href =`geo:0,0?q=${encodeURIComponent(destination)}`;
      }
    },
  },
};
</script>

<style>
.userDetails h2 {
  font-size: 22px;
  color: #092e87;
  font-weight: normal;
}

.notes {
  font-size: 22px;
  color: #092e87;
  font-weight: normal;
}

.detail-list {
  list-style: none;
  padding: 0;
}

.detail-list li {
  color: #092e87;
  font-size: 18px;
  line-height: 40px;
}

.detail-list li span {
  color: #000;
}

.detail-list li a {
  color: #fe5000;
  text-decoration: none;
}

.location-map {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 5px #999;
}

.location-map h2 {
  color: #092e87;
  font-size: 18px;
  padding: 17px 17px;
  background: #d9d9d9;
  font-weight: normal;
}

.googleLink {
  font-size: 17px;
  color: #000;
  margin-top: 30px;
}

.googleLink a {
  color: #fe5000;
}

.notesBox {
  padding: 15px;
  background: #f8f8f8;
  min-height: 150px;
  overflow: auto;
  border: 1px solid #747171cf;
  border-radius: 10px;
  margin-bottom: 30px;
}

.main-button {
  background: #fe5000 !important;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px !important;
}

.main-button:hover {
  background-color: #092e87;
  color: #fff;
}

.locationMap {
  height: 255px;
}

.text-orange {
  color: #fe5000;
}

.underline {
  color: #092e87;
  font-weight: 600;
  cursor: pointer;
}

.googleLink.googleLinkMob {
  display: none;
}

.clickSec p {
  font-size: 15px;
}

.clickSec p:nth-child(2) img {
  width: 60px;
}

.clickBtn {
  /* background: #fff0e9; */
  background: transparent !important;
  padding: 0.5rem;
  border-radius: 5px;
  color: #092e87 !important;
}

.sourceLogo {
  height: 32px;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .locationMap {
    height: 235px;
  }
}

@media screen and (max-width: 767px) {
  .main-button:hover {
    background-color: #fe5000 !important;
    color: #fff;
  }

  .locationMap {
    display: none;
  }

  .googleLink {
    display: none;
  }

  .googleLink.googleLinkMob {
    display: block;
    padding: 0 17px;
    margin-top: 10px;
  }

  .notesBox {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .secMain .form-group {
    display: flex;
    margin-bottom: 0.2rem;
  }

  .secMain label {
    font-size: 16px;
    min-width: 150px;
    font-weight: 600;
  }

  .secMain p {
    font-size: 16px;
  }

  .clickSec p:nth-child(2) img {
    margin-right: 0.5rem;
  }

  .clickSec p:nth-child(2) {
    text-align: left;
    margin-top: 2rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
