<template>
  <div class="container">
    <div class="quoteTotal">
      <h2>Quote Total :</h2>
      <h3 v-if="quoteSummary.total_text != null">
        <small>$</small>{{ quoteSummary.total_text.replace("$", "") }}
      </h3>
    </div>
    <div class="quoteLinks mt-3 mb-5">
      <a
        class="primaryButton d-flex align-items-center"
        @click="viewQuoteClicked"
        >View Quote
        <img src="../../assets/images/qoute-icon-1.png" class="ms-2" alt=""
      /></a>
      <a
        class="primaryButton d-flex align-items-center"
        @click="editQuoteClicked"
        >Edit Quote
        <img src="../../assets/images/edit-icon-1.png" class="ms-2" alt=""
      /></a>
    </div>
    <ImageGallery :isBefore="0"></ImageGallery>
    <!-- <hr class="mt-4 mb-3">

        <div class="col-12">
            <h3 class="imagUp-heading">Add images for the system before evaluation and fixing.</h3>
        </div>

        <div class="text-center mt-3">
            <button class="btn main-button">Upload images</button>
            <p>(Maximum 8 images allowed)</p>
        </div>

        <div class="row justify-content-center align-items-end">
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/system-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/gallery-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/system-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/gallery-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div>

            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/system-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/gallery-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/system-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div> 
            <div class="col-md-3">
                <div class="imgBox-wrap">
                    <div class="imgBox">
                        <img src="@/assets/images/gallery-img.png" class="img-fluid">
                    </div>
                    <span>Delete</span>
                </div>
            </div>
        </div> -->

    <!-- Modal -->
    <div
      class="modal fade modal-lg"
      ref="modal"
      id="exampleModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <QuoteModal :quoteSummary="summary"></QuoteModal>
      </div>
    </div>

    <div class="text-center mt-3">
      <button @click="updateSummary" class="btn main-button">
        Submit Quote
      </button>
    </div>

    <div
      class="text-center mt-3 trade-call-text"
      v-if="
        quoteDetail.partner_info != null &&
        quoteDetail.partner_info.is_tradecall_fee_applicable == 1
      "
    >
      <h2>
        {{
          "Important: " +
          quoteDetail.partner_info.tradecall_fee_text +
          " Trade Call Fee to be collected from " +
          quoteDetail.consumer.full_name
        }}
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QuoteModal from "@/components/quote_modal/QuoteModal.vue";
import ImageGallery from "@/components/image_gallery/ImageGallery.vue";
import { Modal } from "bootstrap";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    QuoteModal,
    ImageGallery,
  },

  data() {
    return {
      summary: null,
    };
  },
  computed: {
    ...mapGetters([
      "storeQuoteData",
      "quoteDetail",
      "quoteSummary",
      "updateQuoteSummary",
    ]),
  },

  watch: {
    quoteSummary: function (data) {
      if (data != null) {
        this.summary = data;
        this.modal = new Modal(this.$refs.modal);
        this.modal.show();
      }
    },

    updateQuoteSummary: function () {
      toast.success("Quote has been updated successfully.");
      this.$router.push({ path: `/quote/detail/${this.quoteDetail.id}` });
    },
  },

  mounted() {
    this.clearState();

    var params = {
      manual_quote_id: this.quoteDetail.id,
    };

    // if(this.quoteDetail.status.technician.status != 0){

    //     params['status'] = "submitted"
    // }

    this.getQuoteSummary(params);
  },

  methods: {
    ...mapActions([
      "getQuoteSummary",
      "setCommponent",
      "postQuoteSummary",
      "clearState",
    ]),

    editQuoteClicked() {
      this.setCommponent("QuoteItems");
    },

    viewQuoteClicked() {
      this.modal = new Modal(this.$refs.modal);
      this.modal.show();
    },

    updateSummary() {
      this.$dialog.show({
        title: "Submit Quote",
        text: "Are you sure you want to submit this quote?",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.postQuoteSummary({
            manual_quote_id: this.quoteDetail.id,
          });
        },
      });
    },

    getPrice(ammount) {
      if (ammount != null) {
        ammount.replace("$", "");
      }
      return ammount;
    },
  },
};
</script>

<style>
.primaryButton {
  font-size: 14px;
  padding: 0.5rem 1rem;
  background: #fd5001;
  text-decoration: none;
  border-radius: 5px;
}
.imgBox {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  height: 120px !important;
  width: 130px !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #000;
  justify-content: center;
}

.imgBox img {
  width: auto;
  max-height: 100%;
  border-radius: 0;
  transition: all ease 0.3s;
}

.imgBox-wrap {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.imgBox-wrap:hover img {
  border-radius: 0;
  transition: all ease 0.3s;
  transform: scale(1.1);
}

.imgBox-wrap:hover .imgBox {
  border: 2px solid #fe5000;
}

.imgBox-wrap span {
  color: #fe5000;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

.imgBox-wrap:hover span {
  color: #092e87;
}

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}

.main-button:hover {
  background-color: #092e87;
  color: #fff;
}

.quoteTotal {
  background-color: #092e87;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.quoteTotal h2 {
  margin: 0;
  font-size: 22px;
}

.quoteTotal h3 {
  margin: 0;
  font-size: 36px;
}

.quoteTotal h3 small {
  font-size: 20px;
  position: relative;
  top: -10px;
}

.quoteLinks {
  display: flex;
  justify-content: space-between;
}

.quoteLinks u {
  color: #fe5000;
  font-weight: 600;
  cursor: pointer;
}

.imagUp-heading {
  font-size: 20px;
  font-weight: 500;
  color: #092e87;
  text-align: center;
}

/* .trade-call-text h2 {
  color: red;
  margin-top: 30px;
  font-size: 22px;
  font-style: italic;
  font-weight: bolder;
} */
.trade-call-text h2 {
  font-size: 18px;
  background: #fff0e9;
  width: max-content;
  margin: 2rem auto 0;
  display: table;
  padding: 0.8rem 1.5rem;
  color: #fd5001;
  border-radius: 15px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .trade-call-text h2 {
    width: auto;
  }
}
</style>
