<template>
  <div :id="index" class="editSummaryCard mt-3 p-3 rounded">
    <div class="form-group mb-3">
      <label class="mb-1">Title</label>
      <div class="d-flex align-items-start">
        <div class="autocompleteSearch position-relative w-100">
          <div class="overlay-form-control">
            <input
              id="name"
              type="text"
              class="form-control"
              placeholder="Enter title"
              v-model="item.option"
              @input="focusCheck()"
              @keyup.enter="focusCheckOut()"
            />

            <h6 :class="item.value == 3 ? 'mb-0 bgRed' : 'mb-0 bgYellow'"></h6>
          </div>
          <ul v-if="filteredList.length" class="edit_dropdown mb-0">
            <li
              v-for="(item, index) in filteredList"
              :key="index"
              @click="selectOption(item)"
            >
              {{ item.option }}
            </li>
          </ul>
        </div>
        <!-- <div class="custom-switch mt-2 ms-2">
          <input type="checkbox" :id="index" :true-value="2" :false-value="3"
            :disabled="item.is_service_fee_charges_field == 1" v-model="item.value" />
          <label :for="index" class="label-hidden"></label>
        </div> -->
      </div>
      <small class="text-danger fw-bold" v-if="item.is_error_title">
        *Title field is required
      </small>
    </div>

    <div class="typeFormGroup form-group mb-3">
      <h6 class="mb-2">Type</h6>
      <div class="radioCheck customRadiocheck d-flex justify-content-between">
        <div class="form__radio-group">
          <input
            type="radio"
            class="form__radio-input"
            :value="3"
            :id="item.diagnostic_option_id + '-red-' + index"
            :name="item.diagnostic_option_id + '-' + index"
            v-model="item.value"
            :disabled="item.is_service_fee_charges_field == 1"
            checked
            @click="checkValue(item)"
          />
          <label
            :for="item.diagnostic_option_id + '-red-' + index"
            class="form__radio-label redRadio"
          >
            <span class="form__radio-button">Non Operation / Critical</span>
          </label>
        </div>
        <div class="form__radio-group">
          <input
            type="radio"
            class="form__radio-input"
            :value="2"
            :id="item.diagnostic_option_id + '-yellow-' + index"
            :name="item.diagnostic_option_id + '-' + index"
            v-model="item.value"
            :disabled="item.is_service_fee_charges_field == 1"
            @click="checkValue(item)"
          />
          <label
            :for="item.diagnostic_option_id + '-yellow-' + index"
            class="form__radio-label yellowRadio"
          >
            <span class="form__radio-button"
              >Not operating as per manufacturer's specification
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="form-group mb-3">
          <label class="mb-1">Select Action</label>

          <select
            v-if="item.is_service_fee_charges_field != 1"
            class="form-select mb-2"
            aria-label="Default select example"
            v-model="item.selected_service"
            @change="onChange(item)"
          >
            <option
              :value="dropDownDefaultValue(item.selected_service)"
              selected
              disabled
            >
              Select Service
            </option>
            <option
              v-for="(opt, idx) in getBookingActions(item.value)"
              :key="idx"
              :value="opt"
              :selected="
                opt.selected_service != null &&
                opt.id == item.selected_service.id
              "
            >
              {{ opt.new_display_name }}
            </option>
          </select>

          <span class="form-select mb-2 disabled" v-else>
            {{ item.booking_action_new_display_name }}</span
          >

          <small class="text-danger fw-bold" v-if="item.is_error_action">
            *Action field is required
          </small>
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="mb-1">Service Fee ($)</label>
      <input
        type="tel"
        class="form-control"
        placeholder="Enter Service Fee ($)"
        v-model="item.service_fee"
        @paste="disablePaste($event)"
        @keypress="isNumber"
        @input="isNumber"
        @drop.prevent
        @dragover.prevent
      />

      <small class="text-danger fw-bold" v-if="item.is_error_serviceFee">
        *Service fee field is required
      </small>
    </div>

    <div class="editPartSec">
      <div class="form-group mb-3">
        <div
          class="button-form-group mb-1 d-flex align-items-center justify-content-between"
        >
          <label>Parts</label>
          <span v-if="partsTotal > 0" class="lightGreyBg orangeText"
            >Total: ${{ partsTotal }}</span
          >
        </div>
        <button
          :disabled="isDisableParts(item.selected_service)"
          @click="partsClicked(item)"
          class="d-flex align-items-center justify-content-between"
        >
          <span v-if="item.parts && item.parts.length > 0"
            >{{ item.parts.length }} Parts</span
          >
          <span v-else>Select Parts</span>
          <img
            src="../../assets/images/button-icon.png"
            alt=""
            :class="isDisableParts(item.selected_service) ? 'disableImg' : ''"
          />
        </button>

        <small class="text-danger fw-bold" v-if="item.is_error_parts">
          *Please select parts
        </small>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <div class="form-group">
        <div class="form-check d-flex p-0">
          <div class="form__checkbox d-flex align-items-center me-5">
            <input
              type="checkbox"
              :id="index"
              name="check"
              v-model="item.is_service_warranty"
              :disabled="item.is_service_fee_charges_field == 1"
              :class="item.is_service_fee_charges_field == 1 ? 'disabled' : ''"
            /><label
              :for="index"
              :class="
                item.is_service_fee_charges_field == 1
                  ? 'blueText disabled'
                  : 'blueText'
              "
              >Labor Warranty</label
            >
          </div>
        </div>
      </div>
      <div class="deleteCard">
        <a @click="deleteButton()">
          <img src="../../assets/images/delete-fill-icon.png" alt="" />
        </a>
      </div>
    </div>
    <!-- selected part Modal -->
    <div
      class="modal fade"
      id="selectPartModal"
      ref="selectPartModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <nest_select_part_modal
              :item="selectedItem"
              :bookingParts="bookingParts"
              @event="callback"
              :isOpen="isOpen"
              :index="index"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nest_select_part_modal from "../../components/nest_components/nest_select_part_modal.vue";
import { Modal } from "bootstrap";
export default {
  components: { nest_select_part_modal },
  props: [
    "item",
    "index",
    "bookingServices",
    "bookingParts",
    "diagnosisItems",
    "bookingDiagnosisOptions",
  ],
  data() {
    return {
      autoList: [],
      isAutoEnable: false,
      selectedItem: null,
      isOpen: false,
    };
  },

  computed: {
    filteredList() {
      if (
        this.item.option == "" ||
        this.item.option == undefined ||
        !this.isAutoEnable
      ) {
        return [];
      }

      return this.bookingDiagnosisOptions.filter((obj) => {
        return Object.keys(obj).some((key) => {
          ("" + obj[key])
            .toLowerCase()
            .includes(this.item.option.toLowerCase());
        });
      });
    },

    partsTotal() {
      let res = 0;
      if (this.item && this.item.parts) {
        this.item.parts.forEach((element) => {
          if (element.cost_with_markup.length < 0) {
            element.cost_with_markup = 0;
          }
          if (!element.is_warranty) {
            res =
              Number(res) +
              Number(element.quantity) * Number(element.cost_with_markup);
          }
        });
      }
      return res;
    },
  },

  mounted() {
    const modalElement = document.getElementById("selectPartModal");
    modalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.addEventListener("hidden.bs.modal", this.removeOverflowHidden);

    // Get the input element
    const inputField = document.getElementById("name");
    let self = this;

    // Add an event listener for the blur event
    inputField.addEventListener("mouseout", function (event) {
      // Handle the focus out event
      console.log("out");
      // self.focusCheckOut()
    });
  },

  watch: {
    "item.option": {
      handler() {
        if ((this.item["option"] + "").length > 0) {
          this.item["is_error_title"] = false;
        }
      },
      deep: true,
    },

    "item.selected_service": {
      handler() {
        console.log("action");

        if (this.item.selected_service != null) {
          this.item["is_error_action"] = false;
        }
      },
      deep: true,
    },

    "item.service_fee": {
      handler() {
        if ((this.item["service_fee"] + "").length > 0) {
          this.item["is_error_serviceFee"] = false;
        }
      },
      deep: true,
    },

    "item.parts": {
      handler() {
        if (this.item["parts"] && this.item["parts"].length > 0) {
          this.item["is_error_parts"] = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    addOverflowHidden() {
      document.documentElement.classList.add("overflow-hidden");
    },

    removeOverflowHidden() {
      document.documentElement.classList.remove("overflow-hidden");
    },

    onChange(data) {
      data["booking_action_id"] = data.selected_service.id;
      if (this.isDisableParts(data.selected_service)) {
        data.parts = [];
      }
    },

    isDisableParts(service) {
      if (
        service == undefined ||
        service == null ||
        (service != null && service.id == "5")
      ) {
        return true;
      } else {
        return false;
      }
    },

    callback(data, opt) {
      this.isOpen = false;
      if (opt != "close") {
        this.item.parts = data.parts;
      }
      this.modal.hide();
    },

    selectOption(data) {
      this.item.option = data.option;
      this.item.diagnostic_option_id = data.id;
      this.isAutoEnable = false;
    },

    focusCheck() {
      this.isAutoEnable = true;
    },
    focusCheckOut() {
      this.isAutoEnable = false;
    },

    removeItem(index) {
      this.additionalItems.splice(index, 1);
    },

    deleteButton() {
      this.$emit("event", this.index);
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 37 || charCode === 39 || charCode === 9 || (charCode >= 48 && charCode <= 57) || charCode === 46) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },

    partsClicked(data) {
      if (this.item.is_service_fee_charges_field != 1) {
        this.selectedItem = data;
        if (!this.selectedItem.parts) {
          this.selectedItem.parts = [];
        }
        this.isOpen = true;
        this.modal = new Modal(this.$refs.selectPartModal);
        this.modal.show();
      }
    },

    closeModal() {
      this.isOpen = false;
      this.modal.hide();
    },

    getBookingActions(value) {
      if (value == 3) {
        return this.bookingServices.actions.required;
      } else if (value == 2) {
        return this.bookingServices.actions.recommended;
      }
    },

    checkValue(data) {
      data.booking_action_id = null;
      data.selected_service = null;
    },

    dropDownDefaultValue(data) {
      if (data == undefined) {
        return data;
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
.bgYellow {
  background-color: #ffb000;
}

.bgRed {
  background-color: #f6635c;
}

.editSummaryCard {
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
}

.editSummaryCard .overlay-form-control {
  position: relative;
  width: 100%;
}

.editSummaryCard .overlay-form-control h6 {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.editSummaryCard .custom-switch {
  width: 15%;
}

.editSummaryCard .custom-switch input[type="checkbox"] + .label-hidden {
  background: linear-gradient(to right, #f6635c 50%, #ffb000 50%);
}

.editSummaryCard .custom-switch input[type="checkbox"] + .label-hidden:after {
  background-color: #f6635c;
  border: 1px solid #fff;
}

.editSummaryCard
  .custom-switch
  input[type="checkbox"]:checked
  + .label-hidden:after {
  background-color: #ffb000;
}

.button-form-group span {
  font-size: 13px;
  border-radius: 50px;
  padding: 0.2rem 0.5rem;
}

.editSummaryCard .editPartSec button {
  background: #fff;
  border: 1px solid #dee2e6;
  box-shadow: none;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  border-radius: 5px;
  padding: 0 0 0 0.5rem;
  height: 40px;
  color: #000;
}
.editSummaryCard .editPartSec button:disabled {
  opacity: 0.5;
}
.editSummaryCard button img {
  width: 40px;
}

.edit_dropdown {
  z-index: 1000 !important;
  position: absolute;
  background: white;
  width: 100% !important;
  overflow: scroll;
  max-height: 200px !important;
  overflow-x: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-left: 0px;
  border: 1px solid #c5c5c5;
  top: -10;
}

.edit_dropdown::-webkit-scrollbar {
  width: 5px;
}

.edit_dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
}

.edit_dropdown::-webkit-scrollbar-thumb {
  background: #fe5000;
  border-radius: 10px;
}

.edit_dropdown li {
  cursor: pointer;
  list-style: none;
  padding: 5px 10px;
}

.edit_dropdown li:hover {
  background: #092e87;
  color: #fff;
}
/* .editSummaryCard {
  position: relative;
}
.editSummaryCard .deleteCard {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.editSummaryCard .deleteCard img {
  width: 25px;
} */

.editSummaryCard
  .form-group
  .customRadiocheck
  [type="radio"]:checked
  + label:before,
.editSummaryCard
  .form-group
  .customRadiocheck
  [type="radio"]:not(:checked)
  + label:before {
  right: auto;
  top: 0;
  left: 0;
}
.editSummaryCard .form-group [type="radio"]:checked + label,
.editSummaryCard .form-group [type="radio"]:not(:checked) + label {
  padding-right: 0;
  padding-left: 2rem;
}
.editSummaryCard
  .form-group
  .customRadiocheck
  [type="radio"]:checked
  + label:after,
.editSummaryCard
  .form-group
  .customRadiocheck
  [type="radio"]:not(:checked)
  + label:after {
  /* background: #fff; */
  top: 5px;
  left: 5px;
  right: auto;
}

.disableImg {
  opacity: 0.5 !important;
}
.overflow-hidden {
  overflow: hidden;
}
.disabled {
  opacity: 0.5 !important;
}
</style>
