<template>
    <div class="main" v-if="visible">
        <div class="center">
            <GridLoader/>
        </div>
    </div>
</template>

<script>
import EventBus from "@/plugins/EventBus";

import GridLoader from "@/components/f_loader/GridLoader.vue";


export default {

    components: {
        GridLoader,
    },

    data() {
        return {
            visible: false,
        }
    },

    mounted() {
        EventBus.on("loadershow", () => {
            this.show()
        });

        EventBus.on("loaderhide", () => {
            this.hide()
        });
    },

    methods: {
        hide() {
            this.visible = false;
        },

        show() {
            this.visible = true;
        }
    },

}
</script>


<style>
.main {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.2);
    z-index: 10000;
}

.center{
    width: 75px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
</style>
    