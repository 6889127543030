<template>
  <main class="selectUnitPg">
    <div class="container">
      <!-- <BarcodeScanner /> -->
      <!-- :class="['selectUnitPgHead', 'sticky', { fixed: isFixed }]" -->
      <div class="selectUnitPgHead">
        <div class="heading d-flex align-items-center justify-content-between">
          <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
            <b>Select a Unit </b> - Job ID
            <span class="orangeText">{{ jobId }}</span>
          </h4>
          <nest_notes_modal />
        </div>
        <div class="breadcrumbs border rounded p-2">
          <ul class="d-flex list-inline mb-0">
            <li>
              <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
            </li>
            <li class="blueText">Select a Unit - Job ID {{ jobId }}</li>
          </ul>
        </div>
        <div class="chipsSec">
          <!-- <carousel ref="carousel" :items-to-show="3" v-model="currentIndex"> -->
          <ul class="list-inline mb-0 d-flex flex-wrap">
            <li v-for="(item, index) in units" :key="index">
              <button :class="index == currentIndex ? 'active' : ''" @click="chipSelection(index)">
                {{ "Unit " + (index + 1) }}
              </button>
            </li>
          </ul>
          <!-- </carousel> -->
        </div>
      </div>
      <div class="review">
        <div class="row d-flex align-items-center justify-content-between">
          <div class="col-lg-8 col-md-8 col-sm-8 col-12">
            <p class="my-3 blueText">
              Please review and update the unit information that are currently
              under evaluation
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-12">
            <h5 class="mb-0 orangeText fw-bold text-end" v-if="isUnitsApiHit">
              Available Units: <span class="blueText">{{ units.length }}</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div>
            <carousel ref="carouselSec" v-if="units.length > 0 && isUnitsApiHit" @slide-start="pageChange"
              v-model="currentIndex" :items-to-show="1" :navigation-enabled="isMobile && units.length > 0"
              adjustableHeight="true" :touchDrag="isMobile && units.length > 1">
              <slide v-for="(item, index) in units" :key="index">
                <unit_detail v-if="index == carouselSlideIndex" :carouselSlideIndex="carouselSlideIndex" :item="item" :currentIndex="currentIndex"
                  :units="units" :configData="configData" @event="unitCallback" @openModalQR="openModalQR" :errorObject="errorObject">
                </unit_detail>
              </slide>
              <template #addons>
                <navigation v-if="isMobile && units.length > 1" />
                <pagination v-if="isMobile && units.length > 1" />
              </template>
            </carousel>
          </div>
        </div>
      </div>

      <div class="buttons d-flex justify-content-center mt-5">

        <button
          v-if="is_from_mobile != 1"
          @click="backButtonClicked()"
          class="btn buttonDark rounded me-2" > Back
        </button>

        <button v-if="checkIsSave()" @click="saveUnit()" class="btn buttonSecondary rounded me-2">
          <img src="../../assets/images/evaluate-icon.png" class="me-2" alt="" />Save this unit
        </button>

        <button v-else @click="evaluate()" class="btn buttonPrimary rounded me-2">
          <img src="../../assets/images/evaluate-icon.png" class="me-2" alt="" />Evaluate this unit
        </button>

        <button v-if="isAddOptionCheck()" @click="addMoreUnits()" class="btn buttonSecondary rounded">
          <img src="../../assets/images/add-circle.png" class="me-2" alt="" />Add more unit to this location
        </button>
      </div>
    </div>

    <!-- Modal -->
    <div id="addMoreModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      ref="qrDialog">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="closebutton d-flex justify-content-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div class="modalCntnt py-3 px-5">
              <h5 class="mb-0 blueText fw-bold text-center mb-4">
                Add more units
              </h5>
              <div class="form-group mb-3">
                <label class="mb-3 text-center">
                  Enter the number of additional units that you want to add
                  for this location
                </label>
                <input v-model="noOfUnit" type="num" class="form-control" placeholder="Units" @paste="disablePaste"
                  @keypress="isNumber" />
              </div>
              <div class="bottomButtons d-flex justify-content-center mt-4">
                <button class="btn buttonPrimary d-flex align-items-center me-2" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button @click="saveMoreUnit()" class="btn buttonDark d-flex align-items-center">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="qrModals" ref="qrModals" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="modalCntnt pt-1">
              <ModalScanner :showModal="isQRModalOpen" @close="closeQRModal" @scan-result="handleQRScanResult" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="barcodeModals" ref="barcodeModals" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="modalCntnt pt-1">
              <Scanner :showModal="isModalOpen" @close="closeModal"
                @scan-result="handleScanResult" />
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader> -->
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Modal } from "bootstrap";
import unit_detail from "../../components/nest_components/UnitDetail.vue";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
import { useToast } from "vue-toastification";
import ModalScanner from '../../components/nest_components/ModalScanner.vue';
import Scanner from '../../components/nest_components/Scanner.vue'


const toast = useToast();

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    unit_detail,
    nest_notes_modal,
    ModalScanner,
    Scanner
  },

  data() {
    return {
      noOfUnit: "",
      currentIndex: 0,
      jobId: this.$route.params.id,
      units: [{ unit_name: "New / Undiagnosed Unit" }],
      isUnitsApiHit: false,
      isFixed: false,
      configData: [],
      isSaveUnit: false,
      carouselSlideIndex: 0,
      isModalOpen: false,
      isQRModalOpen: false,
      selectedKey: '',
      errorObject: {},
      is_from_mobile: this.$route.query.is_from_mobile
    };
  },

  computed: {
    ...mapGetters([
      "unitsList",
      "apiCallback",
      "postUnitResponse",
      "bindUnitResponse",
      "bookingResponse",
    ]),

    isMobile() {
      return this.isMobileDevice();
    },
  },

  mounted() {
    this.getBookingDetails({
      booking_id: this.jobId,
    });
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    unitsList: function () {
      if (this.isSaveUnit) {
        this.isSaveUnit = false;
        for (let key in this.units){
          this.units[key].id = this.unitsList[key].id;
        }
      } else {
        this.units = this.unitsList;
        if (this.unitsList.length < 1) {
          this.units = [{ unit_name: "New / Undiagnosed Unit" }];
        }
        this.getPreConfig();

        const index = this.units.findIndex(item => item.is_selected == 1);
        if (index !== -1) {
          setTimeout(() => {
            this.currentIndex = index
          }, 50);
        }
        this.isUnitsApiHit = true;

      }
    },

    'units': {
      handler(nval, oVal) {
        let newVal = nval[this.currentIndex]
        let oldVal = oVal ?? nval [this.currentIndex]
        for (const key in newVal) {
          if (newVal[key] !== oldVal[key]) {
            this.errorObject[key] = false
          }
        }
      },
      deep: true,
      immediate: true
    },

    postUnitResponse: function () {
      if (this.isSaveUnit) {
        this.getUnitsData();
      } else {
        this.bindUnit({
          job_id: this.jobId,
          job_type: "1",
          unit_id: this.units[this.currentIndex].id,
        });
      }
    },

    bindUnitResponse: function () {
      this.$router.push({
        path: `/equipment/${this.$route.params.id}`,
        query: {
          unit_id: this.units[this.currentIndex].id,
          is_from_mobile: this.$route.query.is_from_mobile,
        },
      });
    },

    apiCallback: function () { },
    bookingResponse: function () {
      this.handleBookingResponse();
    },
  },

  methods: {
    ...mapActions([
      "getUnitList",
      "getBootMeData",
      "getPreConfig",
      "getConfig",
      "postUnitDetails",
      "bindUnit",
      "getBookingDetails",
    ]),
    handleBookingResponse() {
      if (this.bookingResponse !== null) {
        this.getUnitsData();
      }
    },

    evaluate() {
      if (this.isValid()) {
        this.postUnitDetails({
          source_id: this.jobId,
          source: "booking",
          units: JSON.stringify(this.units),
        });
      }
    },

    getUnitsWithIds() {
      return this.units.filter(function (item) {
        return item.id + "" != "undefined";
      });
    },

    chipSelection(ind) {
      this.currentIndex = ind;
    },

    backButtonClicked() {
      this.$router.go(-1);
    },

    checkIsSave() {
      if (this.units[this.currentIndex] && this.units[this.currentIndex].id) {
        return false;
      } else {
        return true;
      }
    },

    saveUnit() {
      if (this.isValid()) {
        this.isSaveUnit = true;
        this.postUnitDetails({
          source_id: this.jobId,
          source: "booking",
          units: JSON.stringify(this.units),
        });
      }
    },

    addMoreUnits() {
      if(this.units.length  <= 5){
        this.modal = new Modal(this.$refs.qrDialog);
        this.modal.show();
      }
      else{
        toast.error("You can not add more than 5 units.");
      }
    },

    saveMoreUnit() {
      if (this.noOfUnit && (this.noOfUnit + "").length > 0 && this.noOfUnit != "0") {
        if ( (Number(this.units.length)+ Number(this.noOfUnit) ) <= 5) {
          for (let i = 0; i < this.noOfUnit; i++) {
            this.units.push({ unit_name: "New / Undiagnosed Unit" });
            this.configData.push({});
          }
          this.modal.hide();
          setTimeout(() => {
            this.noOfUnit = "";
            this.currentIndex = this.units.length - 1;
          }, 50);
        }
        else {
          toast.error("You can not add more than 5 units.");
        }
      } else {
        toast.error("Enter number of units.");
      }
    },

    getUnitsData() {
      this.getUnitList({
        booking_id: this.$route.params.id,
      });
    },

    jobDetailClicked() {
      this.$router.go(-1);
    },

    unitCallback(option, index) {
      if (option == "delete") {
        this.units.splice(index, 1);
        this.configData.splice(index, 1);
        this.currentIndex = index - 1;
      }
    },

    handleScroll() {
      const scroll = window.scrollY;
      this.isFixed = scroll >= 200;
    },

    disablePaste(event) {
      event.preventDefault();
    },

    isNumber(evt) {
      //validate
      const inputElement = evt.target;
      let value = inputElement.value;
      if (evt.type === 'keypress') {
        const charCode = evt.which || evt.keyCode;
        if (charCode === 8 || charCode === 46 || charCode === 37 || charCode === 39 || charCode === 9 || (charCode >= 48 && charCode <= 57) || charCode === 46) {
          if (charCode === 46 && value.indexOf('.') !== -1) {
            evt.preventDefault();
            return false;
          }
          return true;
        }
        evt.preventDefault();
        return false;
      }
      // Remove invalid characters and multiple decimal points
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      const [integer, decimal] = value.split('.');
      // Restrict length
      if (integer.length > 4) value = integer.slice(0, 4);
      if (decimal) value = value.split('.')[0] + '.' + decimal.slice(0, 2);
      inputElement.value = value;
    },

    isEmpty(value) {
      return (
        value == null ||
        value == undefined ||
        value.length == 0 ||
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },

    isValidRequired(key) {
      if (
        key == "new_capacity" &&
        this.units[this.currentIndex]["is_system_sized_properly"] == "1"
      ) {
        return false;
      } else if (key == "notes") {
        return false;
      } else if (key == "coil_model") {
        return false;
      }else {
        return true;
      }
    },

    isAddOptionCheck() {
      if (
        this.bookingResponse &&
        this.bookingResponse.depp_schedule &&
        this.bookingResponse.depp_schedule.is_allied == "1" &&
        this.bookingResponse.depp_schedule.number_of_equipment_items_covered <=
        1
      ) {
        return false;
      } else {
        return true;
      }
    },

    isValid() {
      let res = true;
      let unit = this.units[this.currentIndex];
      let config = this.configData[this.currentIndex] ?? null;
      let index = -1;

      if (this.isEmpty(unit.unit_name)){
        this.errorObject['unit_name'] = true
        if (index == -1) {
            index = this.currentIndex+'-unit_name';
        }
        res = false;
      } if (this.isEmpty(unit.manufacturer)){
        this.errorObject['manufacturer'] = true
        if (index == -1) {
            index = this.currentIndex+'-manufacturer';
        }
        res = false;
      } if (this.isEmpty(unit.manufacture_date)){
        this.errorObject['manufacture_date'] = true
        if (index == -1) {
            index = this.currentIndex+'-manufacture_date';
        }
        res = false;
      } if (this.isEmpty(unit.system_type_id)){
        this.errorObject['system_type_id'] = true
        if (index == -1) {
            index = this.currentIndex+'-system_type_id';
        }
        res = false;
      } if (this.isEmpty(unit.product_type_id)){
        this.errorObject['product_type_id'] = true
        if (index == -1) {
            index = this.currentIndex+'-product_type_id';
        }
        res = false;
      } if (unit['is_cooling_only'] == 0 && this.isEmpty(unit.heat_type_id)){
        this.errorObject['heat_type_id'] = true
        if (index == -1) {
            index = this.currentIndex+'-heat_type_id';
        }
        res = false;
      } if (config == null){
        res = false;
      } 
        
      if (config) {
        for (var key in config) {
          if (config.hasOwnProperty(key)) {
            if (key != "indoor" && key != "outdoor") {
              for (var indx in config[key].data) {
                let dataItems = config[key].data[indx];
                let dataKey = dataItems.name;
                let value = this.units[this.currentIndex][dataItems.name];
                if (this.isValidRequired(dataKey) && this.isEmpty(value)) {
                  res = false;
                  this.errorObject[dataItems.name] = true
                  if (index == -1) {
                    index = this.currentIndex + '-'+dataItems.name;
                  }
                  // break outerLoop;
                }
              }
            }
          }
        }
      }

      if (!res) {
        toast.error("All fields are required.");
        window.scrollTo({
          top: document.getElementById("" + index).offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }

      return res;
    },

    pageChange(i) {
      this.errorObject = {}
      this.carouselSlideIndex = i.slidingToIndex ?? 0;
    },

    isMobileDevice() {
      return window.screen.width < 1200 ? false : true;
    },

    closeModal() {
      this.isModalOpen = false;
      this.modal.hide();
    },

    closeQRModal() {
      this.isQRModalOpen = false;
      this.modal.hide();
    },


    handleQRScanResult(result) {
      this.units[this.currentIndex][this.selectedKey] = result
      // Yahan aap scanned result ko process kar sakte hain, jaise ke API call ya local state update.
      this.modal.hide();
    },

    handleScanResult(result) {
      this.units[this.currentIndex][this.selectedKey] = result
      // Yahan aap scanned result ko process kar sakte hain, jaise ke API call ya local state update.
      this.modal.hide();
    },

    openModalQR(item, type) {
      //barcodeModals
      if (type == 'barcode') {
        this.selectedKey = item
        this.isModalOpen = true;
        this.modal = new Modal(this.$refs.barcodeModals);
        this.modal.show();
      }
      else {
        this.selectedKey = item
        this.isQRModalOpen = true;
        this.modal = new Modal(this.$refs.qrModals);
        this.modal.show();
      }

    }

  },
};
</script>

<style>
.carousel__slide {
  display: table !important;
}

.unitSelection {
  width: 85% !important;
  margin: 0 auto;
}

.chipsSec button {
  border: 0;
  background: transparent;
  color: #000;
  border-radius: 50px;
  border: 1px solid #000;
  padding: 0.1rem 1rem;
}

.carousel__slide--active button {
  background: #000;
  color: #fff;
}

li.carousel__pagination-item {
  width: 30px;
  height: 30px;
  position: relative;
  top: 0;
  margin: 0 0.1rem;
}

.carousel__prev,
.carousel__next {
  top: auto;
  bottom: -2px;
}

.carousel__prev,
.carousel__prev:hover {
  left: 35%;
  border: 1px solid #fe5000;
  background: #fe5000;
  border-radius: 50%;
  color: #fff;
}

.carousel__next,
.carousel__next:hover {
  right: 35%;
  border: 1px solid #fe5000;
  background: #fe5000;
  border-radius: 50%;
  color: #fff;
}

.carousel__prev--disabled,
.carousel__next--disabled {
  border: 1px solid #fe5000;
  color: #fe5000;
  background: transparent;
  opacity: 1;
}

button.carousel__pagination-button {
  width: 30px;
  height: 30px;
  position: relative;
  top: -14px;
  padding: 0;
  left: -20px;
  border-radius: 50%;
  /* z-index: -1; */
}

.carousel__pagination-button--active:after {
  background: #092e87 !important;
}

button.carousel__pagination-button:after {
  /* opacity: 0; */
  width: 30px;
  height: 30px;
  z-index: 99;
  /* left: -10px; */
  border: 1px solid #ccc;
  background: transparent;
  border-radius: 50%;
  z-index: -1;
  position: relative;
}

.carousel__pagination {
  counter-reset: carousel-counter;
  /* Initialize the counter */
  padding: revert-layer;
}

.carousel__pagination-item {
  counter-increment: carousel-counter;
  /* Increment the counter for each li */
  list-style: none;
  /* Remove default bullets */
}

.carousel__pagination-item::marker {
  font-size: 16px;
  content: counter(carousel-counter);
  /* Display the counter value */
  margin-right: 8px;
  /* Add some space between the number and the text */
  color: #ccc;
  z-index: 1;
}

.chipsSec ul li {
  /* width: 30%; */
  margin: 0.2rem;
}

.chipsSec ul li button.active {
  background: #000;
  color: #fff;
}
</style>
