<template>
  <main class="summaryPg" v-if="isApiCalled">
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Summary </b> - Job ID <span class="orangeText">{{ jobId }}</span>
        </h4>
        <nest_notes_modal />
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
          </li>
          <li>
            <a :href="'/select/unit/' + jobId" class="greyText">Units</a>
          </li>
          <li>
            <a :href="'/equipment/' + jobId + `?unit_id=` + this.$route.query.unit_id
              " class="greyText">Equipments</a>
          </li>
          <li class="blueText">Summary - Job ID {{ jobId }}</li>
        </ul>
      </div>
      <div class="summarySec border-bottom pb-4 mb-4">
        <div class="summaryHead d-flex align-items-center justify-content-between mb-3">
          <h5 class="mb-0 blueText">
            {{
              bookingResponse.system_type
                ? (bookingResponse.system_type ?? "") + " System"
                : ""
            }}
          </h5>
          <a :href="'/equipment/' +
            jobId +
            `?unit_id=` +
            this.$route.query.unit_id +
            '&is_from_mobile=' +
            this.$route.query.is_from_mobile
            " class="editPart d-flex align-items-center p-0 border-0 orangeText">
            Edit
            <img src="../../assets/images/edit-icon-4.png" alt="edit-icon" class="ms-2" />
          </a>
        </div>
        <div class="electricDuckSec" v-if="options.length > 0">
          <div v-if="bookingResponse.is_flat_price_model">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-for="(item, index) in options" :key="index">
              <div class="flatPriceSec rounded">
                <span :class="item.value == 3 ? 'borderLeft borderLeftRed' : 'borderLeft borderLeftYellow'"></span>
                <div class="electricDuckHead rounded d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 fw-bold blackText">{{ item.option }}</h6>
                  <button class="btn buttonSecondary serviceBtn" @click="openPartsModal(item, index)"
                    :disabled="isDisableParts(item.selected_service)">
                    Select Part
                  </button>
                </div>
                <div class="selectedPart mt-2" v-if="item.parts && item.parts.length > 0">
                  <h6>Selected Parts</h6>
                  <ul class="list-inline mb-0">
                    <li v-for="(part, index) in item.parts" :key="index"
                      class="d-flex align-items-center justify-content-between">
                      <p class="mb-0">{{ part.part_name }}</p>
                      <img src="../../assets/images/cross-orange-border-icon.png" alt="cross-orange-border-icon"
                        @click="removePart(part, item)" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-for="(item, index) in options" :key="index">
              <div :id="index" class="electricSec">
                <h6 class="mb-0 d-flex align-items-center p-2 lightGreyBg rounded mb-3">
                  <img v-if="item.value == 3" src="../../assets/images/electric-head-icon.png" alt="electric-head-icon"
                    class="me-2" />
                  <img v-if="item.value == 2" src="../../assets/images/duck-head-icon.png" alt="electric-head-icon"
                    class="me-2" />
                  {{ item.option }}
                </h6>

                <div class="form-group mb-3">
                  <label v-if="!bookingResponse.is_flat_price_model" class="mb-1">Select Service</label>
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="w-100 me-2">
                      <select v-if="!bookingResponse.is_flat_price_model" class="form-select me-3"
                        aria-label="Default select example" v-model="item.selected_service" @change="onChange(item)">
                        <option :value="dropDownDefaultValue(item.selected_service)" selected disabled>
                          Select Service
                        </option>
                        <option v-for="(opt, idx) in getBookingActions(item.value)" :key="idx" :value="opt" :selected="opt.selected_service != null &&
                          opt.id == item.selected_service.id
                          ">
                          {{ opt.new_display_name }}
                        </option>
                      </select>
                      <small class="text-danger fw-bold" v-if="item.is_error">
                        {{ item.error }}
                      </small>
                      <!-- service warranty -->
                      <div v-if="!bookingResponse.is_flat_price_model" class="serviceWarranty mt-2 mb-4">
                        <div class="form-group">
                          <div class="form-check d-flex p-0">
                            <div class="form__checkbox d-flex align-items-center">
                              <input type="checkbox" name="check" v-model="item.is_service_warranty" :id="index" />
                              <label class="blueText" :for="index">Labor Warranty</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button class="btn buttonPrimary serviceBtn" @click="openPartsModal(item, index)"
                      :disabled="isDisableParts(item.selected_service)">
                      Select Part
                    </button>
                  </div>
                </div>

                <!-- service warranty -->
                <!-- <div class="serviceWarranty mb-4">
                  <div class="form-group">
                    <div class="form-check d-flex p-0">
                      <div
                        class="form__checkbox d-flex align-items-center me-5"
                      >
                        <input
                          type="checkbox"
                          name="check"
                          v-model="item.is_service_warranty"
                          :id="index"
                        />
                        <label class="blueText" :for="index"
                          >Labor Warranty</label
                        >
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- selected parts -->
                <div class="selectedParts" v-if="item.parts && item.parts.length > 0">
                  <h6 class="blueText mb-3">Selected Parts:</h6>
                  <nest_selected_part :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noItemSelected">
          <h4>
            No items have been selected as required or recommended repairs.
            Either hit "Next" to complete this job or hit the pen icon to
            "Edit".
            <img src="../../assets/images/edit-icon-3.png" alt="edit-icon" class="ms-2" />
          </h4>
        </div>
      </div>

      <!-- additional Item -->
      <div class="additionalSec">
        <div class="additionalTable">
          <nest_additional_table :additionalItems="additionalItems" />
        </div>
      </div>

      <!-- bottomButtons -->
      <div class="bottomButtons d-flex justify-content-center mt-5">
        <button @click="backButtonClicked()"
          class="text-uppercase btn buttonDark d-flex align-items-center rounded me-2">
          Back
        </button>
        <button @click="nextButtonClicked()" class="text-uppercase btn buttonPrimary d-flex align-items-center rounded">
          Next
        </button>
      </div>

      <!-- add items Modal -->
      <div class="modal fade" id="addItemModal" ref="addItemModal" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <!-- <div class="closebutton d-flex justify-content-end">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="hideModal"
                ></button>
              </div> -->
              <div class="modalCntnt pt-3">
                <h5 class="mb-0 blueText fw-bold text-center mb-3">
                  Misc. Items
                </h5>
                <div class="form-group mb-3">
                  <label class="mb-2">Title</label>
                  <input type="text" class="form-control" placeholder="Add" />
                </div>
                <div class="form-group mb-3">
                  <label class="mb-2">Price</label>
                  <input type="text" class="form-control" placeholder="$12" />
                </div>
                <div class="bottomButtons d-flex justify-content-center mt-5">
                  <button class="btn buttonPrimary d-flex align-items-center me-2" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button class="btn buttonDark d-flex align-items-center">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- selected part Modal -->
      <div class="modal fade" id="selectPartModal" ref="selectPartModal" data-bs-backdrop="static"
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <nest_select_part_modal v-if="this.bookingResponse.is_flat_price_model" :item="selectedItem"
                :bookingParts="componentList" @event="callback" :isOpen="isOpen" :index="selectedIndex" />
              <nest_select_part_modal v-else :item="selectedItem" :bookingParts="bookingParts" @event="callback"
                :isOpen="isOpen" :index="selectedIndex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nest_selected_part from "../../components/nest_components/nest_selected_part.vue";
import nest_additional_table from "../../components/nest_components/nest_additional_table.vue";
import nest_select_part_modal from "../../components/nest_components/nest_select_part_modal.vue";
import { Modal } from "bootstrap";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {
    nest_selected_part,
    nest_additional_table,
    nest_select_part_modal,
    nest_notes_modal,
  },

  computed: {
    ...mapGetters([
      "preChecksDetail",
      "getDiagnosisOptions",
      "bookingServices",
      "bookingParts",
      "storeChecksResponse",
      "collectDataResponse",
      "allDiagnosisOptions",
      "bookingResponse",
      "componentList",
      "preChecksStore"
    ]),

    showElectricCard() {
      return this.selectedService && this.selectedPart;
    },
  },

  data() {
    return {
      modal: null,
      selectedService: "",
      selectedPart: "",
      options: [],
      additionalItems: [],
      initialChecks: [],
      preDiagnosis: null,
      jobId: this.$route.params.id,
      unit_id: this.$route.query.unit_id,
      selectedItem: null,
      selectedIndex: 0,
      isOpen: false,
      isApiCalled: false,
    };
  },

  mounted() {
    this.getBookingDetails({
      booking_id: this.jobId,
    });
  },

  beforeUnmount() {
    const modalElement = document.getElementById("selectPartModal");
    modalElement.removeEventListener("show.bs.modal", this.addOverflowHidden);
    modalElement.removeEventListener(
      "hidden.bs.modal",
      this.removeOverflowHidden
    );
  },

  watch: {

    preChecksStore: function () {
      this.$router.push({
        path: `/summary-detail/${this.$route.params.id}`,
        query: {
          unit_id: this.unit_id,
          is_from_mobile: this.$route.query.is_from_mobile,
        },
      });
    },

    preChecksDetail: function () {

      if (this.preChecksDetail.length <= 0) {

        var params = {}
        params["is_diagnosis_fee_applicable"] = 0
        params["booking_id"] = this.jobId;
        this.storePreChecksDetails(params);

      }
      else {
        this.$router.push({
          path: `/final-evaluation/${this.$route.params.id}`,
          query: {
            unit_id: this.unit_id,
            is_from_mobile: this.$route.query.is_from_mobile,
          },
        });
      }

    },
    bookingServices: function () {
      this.getAllDiagnosisOption({
        booking_id: this.jobId,
      });
    },

    storeChecksResponse: function () {

      if (this.storeChecksResponse.diagnosis_type == "diagnosis_items") {
        this.storeChecks({
          booking_id: this.jobId,
          diagnosis_type: "additional_items",
          diagnosis_data: JSON.stringify(this.additionalItems),
        });
      } else if (
        this.storeChecksResponse.diagnosis_type == "final_pre_checks"
      ) {
        this.getPreChecksDetails({
          booking_id: this.jobId,
        });
        // this.$router.push({
        //   path: `/final-evaluation/${this.$route.params.id}`,
        //   query: {
        //     unit_id: this.unit_id,
        //     is_from_mobile: this.$route.query.is_from_mobile,
        //   },
        // });
      } else {
        this.postCollectDataApi();
      }
    },

    allDiagnosisOptions: function () {
      this.handleDiagnosisResponse();
    },

    collectDataResponse: function () {
      this.storeChecks({
        booking_id: this.jobId,
        diagnosis_type: "final_pre_checks",
        diagnosis_data: JSON.stringify({}),
      });
    },
    bookingResponse: function () {
      this.handleBookingResponse();
    },
  },

  methods: {



    removePart(data, item) {
      var i = item;
      i.parts = i.parts.filter(function (item) {
        return item !== data;
      });
    },

    addOverflowHidden() {
      document.documentElement.classList.add("overflow-hidden");
    },

    removeOverflowHidden() {
      document.documentElement.classList.remove("overflow-hidden");
    },

    ...mapActions([
      "getPreChecksDetails",
      "getDiagnosisOption",
      "getBookingServices",
      "getBookingParts",
      "getAllDiagnosisOption",
      "storeChecks",
      "postCollectData",
      "getBookingDetails",
      "getComponentList",
      "storePreChecksDetails"
    ]),
    handleBookingResponse() {
      if (this.bookingResponse !== null) {
        this.getBookingServices({ booking_id: this.jobId });

        if (this.bookingResponse.is_flat_price_model) {
          this.getComponentList({});
        } else {
          this.getBookingParts({
            category_id: "0",
            booking_id: this.jobId,
          });
        }
      }
    },
    postCollectDataApi() {
      this.postCollectData({
        booking_id: this.jobId,
        additional_items: JSON.stringify(this.additionalItems),
        diagnostic_options: JSON.stringify(this.options),
        initial_check: "[]", //JSON.stringify(this.initialChecks),
        booking_diagnostics: JSON.stringify(this.preDiagnosis),
        booking_notes: "[]",
        booking_pre_checks: "[]",

        equipment_checks: null,
        diagnosis_items: null,
      });
    },

    callback(data, opt) {
      this.isOpen = false;
      if (opt != "close") {
        this.selectedItem.parts = data.parts;
        if (this.selectedItem.parts.length > 0) {
          this.selectedItem["is_error"] = false;
          this.selectedItem["error"] = "";
        }
      }
      this.modal.hide();
    },

    handleDiagnosisResponse() {
      let self = this;
      this.allDiagnosisOptions.forEach(function (obj) {
        if (obj.diagnosis_type == "equipment_checks") {
          self.equipment_checks = obj;
        } else if (obj.diagnosis_type == "diagnosis_items") {
          self.diagnosis_items = obj;
        } else if (obj.diagnosis_type == "additional_items") {
          self.setAdditionalItems(obj);
        } else if (obj.diagnosis_type == "initial_checks") {
          self.setInitialChecks(obj);
        } else if (obj.diagnosis_type == "pre-diagnosis") {
          self.setPreDiagnoisis(obj);
        }
      });

      this.setOptions(this.equipment_checks);
      this.setExistingOptions(this.diagnosis_items);
    },

    onChange(data) {
      data["booking_action_id"] = data.selected_service.id;
      data["is_error"] = false;
      data["error"] = "";
    },

    setOptions(data) {
      let diagnosis_data = JSON.parse(data.diagnosis_data);
      let indoorExistingOptions = diagnosis_data.indoor;
      let outdoorExistingOptions = diagnosis_data.outdoor;
      this.options = indoorExistingOptions.concat(outdoorExistingOptions);

      this.options = this.options.filter(function (item) {
        return item.value != 1 && item.value != 0;
      });

      this.options.sort((a, b) => {
        if (a.value === 2 && b.value === 3) {
          return 1; // a comes before b
        } else if (a.value === 3 && b.value === 2) {
          return -1; // b comes before a
        } else {
          return 0; // no change in order
        }
      });

      this.isApiCalled = true;
    },

    setExistingOptions(data) {
      let diagnosis_data = JSON.parse(data.diagnosis_data);
      let ExistingOptions = diagnosis_data;

      this.options = this.options.map(function (item) {
        if (ExistingOptions.length > 0) {
          ExistingOptions.forEach(function (obj) {
            if (
              parseInt(obj.diagnostic_option_id) ===
              parseInt(item.diagnostic_option_id)
            ) {
              item = obj;
            }
          });
        }
        return item;
      });

      setTimeout(() => {
        const modalElement = document.getElementById("selectPartModal");
        modalElement.addEventListener("show.bs.modal", this.addOverflowHidden);
        modalElement.addEventListener(
          "hidden.bs.modal",
          this.removeOverflowHidden
        );
      }, 200);
    },

    setAdditionalItems(data) {
      let diagnosis_data = JSON.parse(data.diagnosis_data);
      this.additionalItems = diagnosis_data ?? [];
    },

    setPreDiagnoisis(data) {
      let diagnosis_data = JSON.parse(data.diagnosis_data);
      this.preDiagnosis = diagnosis_data ?? null;
    },

    setInitialChecks(data) {
      let diagnosis_data = JSON.parse(data.diagnosis_data);
      this.initialChecks = diagnosis_data ?? [];
    },

    nextButtonClicked() {

      if (this.isValid()) {
        this.storeChecks({
          booking_id: this.jobId,
          diagnosis_type: "diagnosis_items",
          diagnosis_data: JSON.stringify(this.options),
        });
      }

    },

    backButtonClicked() {
      this.$router.go(-1);
    },

    getBookingActions(value) {
      if (value == 3) {
        return this.bookingServices.actions.required;
      } else if (value == 2) {
        return this.bookingServices.actions.recommended;
      }
    },

    dropDownDefaultValue(data) {
      if (data == undefined) {
        return data;
      } else {
        return null;
      }
    },

    isDisableParts(service) {
      if (this.bookingResponse.is_flat_price_model) {
        return false;
      } else {
        if (
          service == undefined ||
          service == null ||
          (service != null && service.id == "5")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    getSelectedParts() {
      return (this.selectedItem != null && this.selectedItem.parts) ?? [];
    },

    closeModal() {
      this.isOpen = false;
      this.modal.hide();
    },

    openPartsModal(data, index) {
      this.selectedItem = data;
      this.selectedIndex = index;
      if (!this.selectedItem.parts) {
        this.selectedItem.parts = [];
      }
      this.isOpen = true;
      this.modal = new Modal(this.$refs.selectPartModal);
      this.modal.show();
    },

    isValid() {
      let res = true;
      let msg = "";
      let index = -1;
      if (this.options) {
         for (var indx in this.options) {
          if (!this.options[indx].booking_action_id) {
            //fllat model handling
            if (this.bookingResponse.is_flat_price_model) {
              msg = "";
              res = true;
              this.options[indx].is_error = false;
              this.options[indx].error = "";
            } else {
              msg = "";
              res = false;
              this.options[indx].is_error = true;
              this.options[indx].error = "*Please select service";

              if (index == -1) {
                index = indx;
              }
            }

            // break outerLoop;
          } else if (
            this.options[indx].booking_action_id == 2 &&
            (!this.options[indx].parts || this.options[indx].parts.length < 1)
          ) {
            this.options[indx].is_error = true;
            this.options[indx].error = "*Please select parts";
            msg = "";
            res = false;
            if (index == -1) {
              index = indx;
            }
            // break outerLoop;
          }
        }
      }

      if (res) {
        this.additionalItems.forEach((element) => {
          if (element.title.length <= 0) {
            msg = "Title field is required.";
            res = false;
          } else if (element.price.length <= 0) {
            msg = "Price field is required.";
            res = false;
          }
        });
      }

      if (!res && msg.length > 0) {
        toast.error(msg);
      }

      if (!res) {
        window.scrollTo({
          top: document.getElementById("" + index).offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }

      return res;
    },
  },
};
</script>

<style>
/* .summaryPg .nestNotesModal button {
  min-width: auto;
  padding: 0 !important;
} */
.editPart img {
  width: 35px;
}

.flatPriceSec {
  box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 17%);
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
}

.borderLeft {
  position: absolute;
  top: 15px;
  left: 0;
  width: 6px;
  height: 30px;
  border-radius: 0 5px 5px 0;
}

.borderLeftRed {
  background: #f6635c;
}

.borderLeftYellow {
  background: #FFB000;
}

.electricDuckHead .buttonSecondary {
  border-radius: 50px !important;
  font-size: 13px !important;
  padding: 0.2rem 0.8rem !important;
}

.selectedPart h6 {
  background: rgba(9, 46, 135, 0.1);
  padding: 0.2rem 0.5rem;
  font-size: 14px;
  color: #092e87;
  border-radius: 3px;
}

.selectedPart ul li {
  position: relative;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}

.selectedPart ul li:before {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #092e87;
}

.selectedPart ul li:last-child {
  margin-bottom: 0;
}

.selectedPart ul li p {
  font-size: 14px;
}

.selectedPart li img {
  width: 25px;
  height: 25px;
}
</style>
