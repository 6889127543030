<template>
  <div class="notes border-bottom">
    <div v-if="isFromJobDetail"
      class="notesHead d-flex justify-content-between align-items-center primaryBg rounded p-2"
      @click="toggleAccordion"
    >
      <h5 class="mb-0 whiteText fw-bold">Notes</h5>
      <span class="accordion-icon" :class="{ rotated: isAccordionOpen }">&#9660;</span>
    </div>
    <div v-if="isAccordionOpen || !isMobile" class="notesBody">
      <ul class="list-inline mb-0">
        <li
          class="d-flex align-items-center justify-content-between"
          v-for="(item, index) in bookingNotes"
          :key="index"
        >
          <div>
            <h6 class="mb-0">{{ item.sub_type_text }}</h6>
            <p class="mb-0">
              {{ convertNotesTimsStampToDate(item.created_at) }}
            </p>
            
            <p v-html="getNotesTxt(item.notes)" class="mb-0"></p>
          </div>
          <button @click="deleteNotesClicked(item)" v-if="Number(item.sender_id) === Number(user_id)" class="p-0 border-0">
            <img src="../../assets/images/trash-icon.png" alt="" />
          </button>
        </li>
      </ul>
      <div class="addNotes my-4">
        <h6 class="orangeText mb-3 fw-bold">Add Notes</h6>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Enter notes here"
            v-model="note"
          />
          <button @click="addNoteButtonClicked()" :class="'border-0 p-0 '+ (sendButtonDisable() ? 'disable' : '')" :disabled="sendButtonDisable()">
            <img src="../../assets/images/send-notes-icon.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  props: ["isFromJobDetail"],
  data() {
    return {
      note: "",
      isAccordionOpen: true,
      isMobile: false,
      user:{},
      user_id: null
      // bookingNotes: [],
    };
  },

  computed: {
    ...mapGetters(["bookingNotes", "deleteNoteResponse", "addNoteResponse"]),
  },

  watch: {
    deleteNoteResponse: function () {
      this.getBookingNotes({
        booking_id: this.$route.params.id,
      });
    },

    addNoteResponse: function () {
      this.note = "";
      this.getBookingNotes({
        booking_id: this.$route.params.id,
      });
    },
  },

  mounted() {
     this.user = JSON.parse(localStorage.getItem('user'));
    this.user_id = this.user.user.user_id;
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  methods: {
    ...mapActions(["deleteBookingNote", "getBookingNotes", "addBookingNote"]),

    getNotesTxt(note){

      return note.replace("\n" , "<br>")
    },

    deleteNotesClicked(data) {
      this.$dialog.show({
        title: "Delete Notes",
        text: "Are you sure you want to delete this note?",
        // we are passing callback method for our confirm button
        onConfirm: () => {
          this.deleteBookingNote({
            note_id: data.id,
            booking_id: this.$route.params.id,
          });
        },
      });
    },

    addNoteButtonClicked() {
      if ((this.note+"").trim().length > 0) {
        this.addBookingNote({
          notes: this.note,
          booking_id: this.$route.params.id,
        });
      }else{
        toast.error("Notes field is required");
        this.note = ''
      }
    },

    sendButtonDisable(){
      let res = true
      if ((this.note+"").trim().length > 0){
        res = false
      }
      return res
    },

    convertNotesTimsStampToDate(val) {
      return moment.unix(val).format("hh:mm A - MM/DD/YYYY");
    },

    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 767;
    },
  },
};
</script>

<style>
.accordion-icon {
  transition: transform 0.3s;
  color: #fff;
  font-size: 20px;
}

.accordion-icon.rotated {
  transform: rotate(180deg);
}

button.border-0.p-0.disable img {
    opacity: 0.5;
}

@media (min-width: 768px) {
  /* .notesBody {
    display: none;
  }
  .notesBody[open] {
    display: block;
  } */
  .accordion-icon {
    display: none;
  }
}
</style>
