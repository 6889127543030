<template>
  <div class="tableHeading lightGreyBg p-2">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-lg-9 col-md-9 col-sm-12 col-12">
        <div class="filters">
          <ul class="list-inline mb-0 d-flex">
            <li>
              <img
                src="../../assets/images/filter-orange-icon.png"
                alt="filter-icon"
              />
              <span class="ms-2">Filters</span>
            </li>
            <li class="filterBadge secondarybg">
              <span class="d-block whiteText">
                <b>Status:</b>
                Completed
              </span>
              <img
                src="../../assets/images/cross-small-icon.png"
                alt="cross-icon"
              />
            </li>
            <li class="filterBadge greyBg">
              <span class="d-block whiteText">
                <b>Service Type:</b>
                Air Conditioning
              </span>
              <img
                src="../../assets/images/cross-small-icon.png"
                alt="cross-icon"
              />
            </li>
            <li class="filterBadge darkGreyBg">
              <span class="d-block whiteText">
                <b>Consumer Name:</b>
                JK Tech
              </span>
              <img
                src="../../assets/images/cross-small-icon.png"
                alt="cross-icon"
              />
            </li>
            <li class="filterBadge primaryBg">
              <span class="d-block whiteText">
                <b>Booking ID:</b>
                8419
              </span>
              <img
                src="../../assets/images/cross-small-icon.png"
                alt="cross-icon"
              />
            </li>
          </ul>
        </div>
        <!-- <h6
          v-if="type == 'inprogress'"
          class="mb-0 fw-bold whiteText d-flex align-items-center"
        >
          In Progress <span class="whitebg orangeText ms-2">12</span>
        </h6>
        <h6 v-else class="mb-0 fw-bold whiteText d-flex align-items-center">
          Completed <span class="whitebg orangeText ms-2">12</span>
        </h6> -->
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="search">
          <input type="text" placeholder="Search" class="form-control" />
          <button type="search">
            <img src="../../assets/images/search-icon.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-striped mb-0">
      <thead>
        <tr>
          <th scope="col">
            Partner's
            <img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
          </th>
          <th scope="col">
            Booking ID<img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
          </th>
          <th scope="col">
            Service Type<img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
          </th>
          <th scope="col">
            Consumer Name<img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
          </th>
          <th scope="col">
            Date<img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
          </th>
          <th scope="col">
            Status<img
              src="../../assets/images/sorting-icon.png"
              alt="sorting-icon"
            />
            <img
              src="../../assets/images/filter-white-icon.png"
              alt="filter-white-icon"
            />
            <div class="selectFilters">
              <ul class="list-inline mb-0">
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" checked />
                    <label class="blackText" for="all">All</label>
                  </div>
                </li>
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" />
                    <label class="blackText" for="evaluation-started"
                      >Evaluation Started
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" />
                    <label class="blackText" for="evaluation-completed"
                      >Evaluation Completed</label
                    >
                  </div>
                </li>
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" />
                    <label class="blackText" for="completed">Completed</label>
                  </div>
                </li>
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" />
                    <label class="blackText" for="inprogress">Inprogress</label>
                  </div>
                </li>
                <li>
                  <div class="form__checkbox d-inline-flex align-items-center">
                    <input type="checkbox" name="check" />
                    <label class="blackText" for="Pending">Pending</label>
                  </div>
                </li>
                <li class="d-flex justify-content-center mt-3">
                  <button class="btn buttonPrimary me-2 rounded">Cancel</button>
                  <button class="btn buttonSecondary rounded">Filter</button>
                </li>
              </ul>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="onJobClicked(item)"
          v-for="(item, index) in data"
          :key="index"
        >
          <td>
            <img
              :src="item.depp_schedule.company_logo"
              class="company-logo"
              alt=""
            />
          </td>
          <td>{{ item.id }}</td>
          <td v-if="item.services.length > 0">{{ item.services[0].title }}</td>
          <td>{{ item.consumer_name }}</td>
          <td>{{ item.booking_date }}</td>
          <td>
            <span
              class="evaluationStart oceanBlueBg whiteText rounded px-2 py-1"
              >{{ item.status_text }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data", "type"],
  components: {},

  data() {
    return {};
  },
  computed: {},

  watch: {},

  mounted() {},

  methods: {
    onJobClicked(item) {
      this.$emit("event", item);
    },
  },
};
</script>

<style></style>
