<template>
<main class="loginPg">
    <div class="container-fluid p-0">
        <div class="row d-flex justify-content-end">
            <div class="col-lg-4 col-md-7 col-sm-12 col-12">
                <div class="loginSec d-flex justify-content-center flex-column">
                    <img src="../../assets/images/fyxifyLogo.png" class="mx-auto d-table mb-4 logo" alt="" />
                    <h4 class="text-center whiteText mb-3">Welcome to fyxify!</h4>
                    <div class="form-group mb-4">
                        <label for="email" class="whiteText mb-1">Email ID:</label>
                        <input type="email" class="form-control" placeholder="Enter email" v-model="email" />
                    </div>
                    <div class="form-group">
                        <label for="password" class="whiteText mb-1">Password:</label>
                        <div class="iconicInput">
                            <input :type="passwordFieldType" class="form-control" :placeholder="placeholder" v-model="password" />
                            <span class="icon password-toggle-icon" @click="togglePasswordVisibility">
                                <img :src="passwordVisible ? showPasswordIcon : hidePasswordIcon" :alt="passwordVisible ? 'Hide Password' : 'Show Password'" />
                            </span>
                        </div>
                    </div>
                    <button class="btn buttonPrimary text-capitalize mx-auto d-table my-5" type="submit" @click=loginClicked() :disabled=issubmitDisabled()> Login
                    </button>
                    <a href="" class="whiteText mx-auto d-table">Reset your Password</a>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

import {
    getBrowserFingerprint
} from '@/helpers/fingerprint';

export default {
    data() {
        return {
            email: 'nrgp@fyx.com',
            password: 'C0ntract0r@1234',
            passwordVisible: false,
            showPasswordIcon: require("../../assets/images/eyeshowIcon.png"),
            hidePasswordIcon: require("../../assets/images/eyehideIcon.png"),
            placeholder: "**********", // You can change this to any placeholder text
            fingerprint: '',

        };
    },

    watch: {
        user: function () {

            if (this.user != 'undefined') {
                localStorage.setItem('user', JSON.stringify(this.user))
                this.$router.push({
                    path: `/dashboard`
                }).then(() => { this.$router.go(0) });

            }

        },
    },

    computed: {
        ...mapGetters(["user"]),

        passwordFieldType() {
            return this.passwordVisible ? "text" : "password";
        },
    },

    async created() {
        this.fingerprint = await getBrowserFingerprint();
    },

    mounted() {
    },

    methods: {
        ...mapActions(["proLogin"]),

        issubmitDisabled() {

            if (this.email.length <= 0 || this.password.length <= 0) {
                return true
            }
            else if(this.email.length > 0 && !this.validateEmail() ){
               return true
            } else {
                return false
            }
        },

        validateEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.email)) {
                return false
            } else {
                return true
            }
        },

        onProLogin() {
            this.proLogin({
                email: this.email,
                password: this.password,
                device_type: 'ios',
                device_id: this.fingerprint,
                user_type : 'technician'

            });
        },
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },

        loginClicked() {
            this.onProLogin()
        }
    },

};
</script>
