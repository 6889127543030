import axios from "axios";
// Add a request interceptor
axios.interceptors.request.use(
  config => {

      var date = new Date();
      var offset = date.getTimezoneOffset();
      let tempUser =  JSON.parse(localStorage.getItem('user'))  
      if(tempUser !=null && tempUser !== 'undefined'){
        let user = JSON.parse(localStorage.getItem('user'))  
        // config.headers.common['_token'] = user._token;
        config.headers.common['Authorization'] = 'Bearer ' + user._token
      }
      config.headers.common['Content-Type'] = 'application/json';
      config.baseURL = process.env.VUE_APP_API_URL;
     
      config.headers.common['Access-Control-Allow-Origin'] = '*';
      config.headers.common['meta'] = 'Platform: Tech-Web, Version';
      config.headers.common['type'] = 'PRO';
      config.headers.common['signature'] = 'debug';
      config.headers.common['tz'] = offset * -1

      if ("post" === config.method.toLowerCase()) {
        // console.log("REQUEST data", config.data);

        if (undefined === config.data) {
          config.data = { timestamp: (new Date()).getTime() }
        } else {
          config.data.timestamp = (new Date()).getTime();
        }
      }
      if ("get" === config.method.toLowerCase()) {
        if (undefined === config.params) {
          config.params = { timestamp: (new Date()).getTime() }
        } else {
          config.params.timestamp = (new Date()).getTime();
        }
      }
      if ("put" === config.method.toLowerCase()) {
        // console.log("REQUEST put", config);
        if (undefined === config.params) {
          config.params = { timestamp: (new Date()).getTime() }
        } else {
          config.params.timestamp = (new Date()).getTime();
        }
      }
    
    return config;
  },
  error => {
    Promise.reject(error)
  });

//Add a response interceptor
axios.interceptors.response.use((response) => {
 
  if(response.data.error_code == 'job_access_denied'){
       window.location.replace("/not-found");
  }
  else{
  // Do something with response data
  return response;
  }
 
}, function (error) {
  return Promise.reject(error);
});