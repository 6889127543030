<template>
<div class="container">

</div>
</template>


<script>
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    components: {

    },

    setup() {

    },

    data() {
        return {
            td : this.$route.query.td ?? ''
        };
    },
    computed: {
        ...mapGetters(["isSilentLogin", "user"])

    },

    watch: {
        user: function () {

            if (this.user != 'undefined') {

                console.log("user" , this.user)
                localStorage.setItem('user', JSON.stringify(this.user))
                this.$router.push({
                    path: `/appointment/detail/${this.user.appointment_id}`,
                    query : {flow_type :this.$route.query.flow_type}
                });
            }

        },
    },

    mounted() {

        this.onSilentLogin()

    },

    methods: {
        ...mapActions(["silentLogin"]),
        onSilentLogin() {
            this.silentLogin({
                td: this.$route.query.td
            });
        },
    },
};
</script>
