<template>
  <!-- {{ summaryDetail }} -->
  <main class="summaryDetailPg">
    <div class="container">
      <div class="heading d-flex align-items-center justify-content-between">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
            <b>Summary </b> - Job ID
            <span class="orangeText">{{ jobId }}</span>
          </h4>
          <nest_notes_modal />
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">

        <ul class="d-flex list-inline mb-0" v-if="is_update == 1">
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
          </li>

          <li>
            <a :href="'/edit-summary/' + jobId" class="greyText">Edit Evaluation</a>
          </li>

          <li class="blueText">Summary - Job ID {{ jobId }}</li>
        </ul>

        <ul class="d-flex list-inline mb-0" v-else>
          <li>
            <a :href="'/job/detail/' + jobId" class="greyText">Job Details</a>
          </li>
          <li>
            <a :href="'/select/unit/' + jobId" class="greyText">Units</a>
          </li>
          <li >
            <a :href="'/equipment/' + jobId+ `?unit_id=`+this.$route.query.unit_id" class="greyText">Equipments </a>
          </li>
          <li>
            <a :href="'/summary/' + jobId+ `?unit_id=`+this.$route.query.unit_id" class="greyText">Summary</a>
          </li>

          <li>
            <a :href="'/final-evaluation/' + jobId+ `?unit_id=`+this.$route.query.unit_id" class="greyText">Finalize Evaluation</a>
          </li>
          <li class="blueText">Summary - Job ID {{ jobId }}</li>
        </ul>

      </div>
      <div class="summaryDetailSec">
        <h5 class="mb-3 blueText">
          Here are the details for the services you have selected
        </h5>
        <div class="row">
          <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12"> -->
          <summary_detail_card :details="summaryDetail" class="mb-3" />
          <div class="col-lg-7 col-md-9 col-sm-12 col-12 mx-auto">
            <p class="mb-0 blueText p-3 rounded lightGreyBg text-end">
              Total Amount:
              <span class="fw-bold">{{ summaryDetail.total_text }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="bottomButtons d-flex justify-content-center mt-5">
        <button @click="backButtonClicked()" class="btn buttonDark d-flex align-items-center me-2 text-uppercase rounded">
          back
        </button>
        <button v-if="!is_update" @click="nextButtonClicked()" class="btn buttonSecondary d-flex align-items-center text-uppercase rounded">
          Submit Evaluation
        </button>
        <button v-else @click="updateButtonClicked()" class="btn buttonSecondary d-flex align-items-center text-uppercase rounded">
          Update Evaluation
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import summary_detail_card from "../../components/nest_components/summary_detail_card.vue";
import nest_notes_modal from "../../components/nest_components/nest_notes_modal.vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    summary_detail_card,
    nest_notes_modal
  },

  computed: {
    ...mapGetters(["summaryDetail", "diagnosisSubmitResponse", "diagnosisUpdateResponse"]),
  },

  data() {
    return {
      jobId: this.$route.params.id,
      unit_id: this.$route.query.unit_id,
      is_update: (this.$route.query.is_update) ?? 0,
    };
  },

  mounted() {
    this.getSummaryDetail({
      booking_id: this.jobId,
    });
  },

  watch: {
    summaryDetail: function () {},

    diagnosisUpdateResponse: function () {
      toast.success("Evaluation has been updated successfully.");
      this.$router.push({
          path: `/job/detail/${this.$route.params.id}`,
        });
    },

    diagnosisSubmitResponse: function () {
      if (this.$route.query.is_from_mobile == 1){
        this.$router.push({
          path: `/success`,
          query: {
            is_from_mobile: this.$route.query.is_from_mobile,
          },
        });
      } else {
        toast.success("Evaluation has been submitted successfully.");
      this.$router.push({
          path: `/job/detail/${this.$route.params.id}`,
        });
      }
    },
  },

  methods: {
    ...mapActions(["getSummaryDetail", "diagnosisSubmit", "diagnosisUpdate"]),

    backButtonClicked() {
      this.$router.go(-1);
    },

    nextButtonClicked() {
      this.$dialog.show({
        title: "Submit Evaluation",
        text: "Are you sure you want to submit this evaluation?",
        onConfirm: () => {
          this.diagnosisSubmit({
            booking_id: this.jobId,
          })
        },
      });
    },

    updateButtonClicked() {
      this.$dialog.show({
        title: "Update Evaluation",
        text: "Are you sure you want to update this evaluation?",
        onConfirm: () => {
          this.diagnosisUpdate({
            booking_id: this.jobId,
          })
        },
      });
    },
  },
};
</script>

// getSummaryDetail
