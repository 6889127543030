<template>
    <div class="alertNote">
        <p class="mb-0 text-center">***If a replacement is recommended, call  
            <a :href="'tel:' + getNumber()" >{{ getFormatedNumber() }} </a>  for pre-authorization ***</p>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
    computed: {
    ...mapGetters([
      "quoteDetail",
    ]),
  },
  methods: {

    numberClicked(){
        
    },

    getNumber(){
        if (this.quoteDetail 
        && this.quoteDetail.partner_info
        && this.quoteDetail.partner_info.status == '1'
        && this.quoteDetail.partner_info.replacement_opportunity_number.length > 0) {


        return this.quoteDetail.partner_info.replacement_opportunity_number
      } else {
        return ""
      }
    },

    getFormatedNumber(){
        if (this.quoteDetail 
        && this.quoteDetail.partner_info
        && this.quoteDetail.partner_info.status == '1'
        && this.quoteDetail.partner_info.replacement_opportunity_number.length > 0) {
            let number = this.quoteDetail.partner_info.replacement_opportunity_number
            let str = number.substr(number.length - 10)
            return str.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
      } else {
            return ""
      }
    }
  }
}
</script>

<style>
.alertNote{
    background: #ff5b01;
    border-radius: 5px;
    padding: 0.8rem;
    margin-bottom: 1rem;
}
.alertNote p{
    color: #fff;
    font-size: 24px;
}
.alertNote a{
    color: #fff;
    font-weight: bold;
}
@media screen and (max-width: 420px) {
    .alertNote p{
    font-size: 16px;
}
}
</style>