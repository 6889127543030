<template>
  <!-- tabsSec start -->
  <div class="tabsSec">
    <div class="row justify-content-center">
      <div class="col-md-3 col-4">
        <div
          :class="'consStep ' + (isImages ? 'active' : '')"
          @click="buttonClicked('Notes')"
        >
          <div class="consTab">
            <div class="consTabImg">
              <img src="@/assets/images/image-icon.png" />
              <!-- <img
                src="@/assets/images/image-icon.png"
                class="img-fluid active-img"
              /> -->
            </div>
            <p class="mb-0">Add Images</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-4">
        <div
          :class="'consStep ' + (isCreateQuote ? 'active' : '')"
          @click="buttonClicked('QuoteItems')"
        >
          <div class="consTab">
            <div class="consTabImg">
              <img src="@/assets/images/quote-icon.png" />
              <!-- <img
                src="@/assets/images/setting-icon-active.png"
                class="img-fluid active-img"
              /> -->
            </div>
            <p class="mb-0">Create Quote</p>
            <span class="stepLine"></span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-4">
        <div
          :class="'consStep ' + (isFinalizeQuote ? 'active' : '')"
          @click="buttonClicked('QuoteSummary')"
        >
          <div class="consTab">
            <div class="consTabImg">
              <img src="@/assets/images/final-quote-icon.png" />
              <!-- <img
                src="@/assets/images/qoute-icon-active.png"
                class="img-fluid active-img"
              /> -->
            </div>
            <p class="mb-0">Finalize Quote</p>
          </div>
          <span class="stepLine"></span>
        </div>
      </div>
      <div class="col-12">
        <h3 v-if="step == 1" class="imagUp-heading">
          Add images for the system before evaluation and fixing.
        </h3>
        <h3 v-else-if="step == 3" class="imagUp-heading mt-5">
          Add images for the system after evaluation and fixing.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      isImages: true,
      isCreateQuote: false,
      isFinalizeQuote: false,
      step: 1,
    };
  },
  computed: {
    ...mapGetters(["componentState"]),
  },

  watch: {
    componentState: function () {
      this.setButtons(this.componentState);
    },
  },

  mounted() {
    this.setButtons(this.componentState);
  },

  methods: {
    ...mapActions(["setCommponent"]),

    buttonClicked(stateData) {
      if (this.step == 1 && stateData == "Notes") {
        this.setCommponent(stateData);
      } else if (this.step == 2 && stateData == "Notes") {
        this.setCommponent(stateData);
      } else if (
        this.step == 3 &&
        (stateData == "Notes" || stateData == "QuoteItems")
      ) {
        this.setCommponent(stateData);
      }
    },

    setButtons(data) {
      this.resetButtons();
      if (data == "Notes") {
        this.step = 1;
        this.isImages = true;
      } else if (data == "QuoteItems") {
        this.step = 2;
        this.isImages = true;
        this.isCreateQuote = true;
      } else if (data == "QuoteSummary") {
        this.step = 3;
        this.isImages = true;
        this.isCreateQuote = true;
        this.isFinalizeQuote = true;
      }
    },
    resetButtons() {
      (this.isImages = false),
        (this.isCreateQuote = false),
        (this.isFinalizeQuote = false);
    },
  },
};
</script>

<style>
.consStep {
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: end;
  justify-content: center;
}
.consStep .consTab {
  background: #efefef;
  padding: 1rem 2rem;
  border-radius: 10px;
  min-width: 200px;
}
.consStep .consTab .consTabImg {
  width: 100px;
  height: 100px;
  background: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 0 auto 0.5rem;
}
.consStep .consTab p {
  color: #333333;
  font-size: 20px;
}
.consStep.active .stepLine {
  left: -80px;
}

.consStep.active .consTab {
  background: #e7edff;
}
.consStep.active .consTab .consTabImg {
  background: #092a8c;
}
.consStep .stepLine {
  position: absolute;
  border: 1px solid #fd5001;
  left: -66px;
  top: 0;
  bottom: 0;
  height: 1px;
  width: 110px;
  margin: auto;
}
.consStep.active .stepLine {
  border-color: #092a8c;
}
.imagUp-heading {
  font-size: 20px;
  font-weight: 500;
  color: #092e87;
  text-align: center;
  background: #e7edff;
  width: -moz-max-content;
  width: max-content;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 20px;
}
/* .consStep h3 {
  color: #00000080;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.consStep.active h3 {
  color: #092e87;
  font-size: 18px;
  text-decoration: underline;
  font-weight: 700;
}

.imagUp-heading {
  font-size: 20px;
  font-weight: 500;
  color: #092e87;
  text-align: center;
} */

/* .active-img {
  display: none;
  margin: 0 auto;
}

.consStep.active img {
  display: none;
}

.consStep.active img.active-img {
  display: block;
  width: 50px;
} */

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}
@media screen and (min-width: 1920px) {
  .consStep .stepLine {
    left: -42px;
    width: 60px;
  }
  .consStep.active .stepLine {
    left: -42px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1920px) {
  .consStep .stepLine {
    left: -42px;
    width: 60px;
  }
  .consStep.active .stepLine {
    left: -42px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .consStep .consTab {
    min-width: 170px;
  }
  .consStep .stepLine {
    width: 80px;
  }
  .consStep.active .stepLine {
    left: -40px;
  }
  .consStep .stepLine {
    left: -50px;
  }
  .consStep .stepLine::after {
    top: -6px;
    right: 0px;
    height: 13px;
    width: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .secondaryButton {
    padding: 0.3rem 0.5rem;
    font-size: 15px;
  }
  .notesHead {
    padding: 0.5rem !important;
  }
  .consStep .stepLine {
    width: 30px;
    left: -27px;
  }
  .consStep .consTab {
    padding: 1rem;
    min-width: 130px;
  }
  .consStep .consTab .consTabImg {
    width: 60px;
    height: 60px;
    padding: 0.5rem;
  }
  .consStep .consTab .consTabImg img {
    max-width: 35px;
  }
  .consStep .consTab p {
    font-size: 15px;
  }
  .equipmentSec {
    margin-top: 2rem !important;
  }
  .unitInfo-heading {
    font-size: 23px;
    text-align: left;
  }
  .imagUp-heading {
    font-size: 18px;
  }
  .main-button {
    font-size: 17px;
    padding: 8px 22px;
  }
  .consStep.active .stepLine {
    left: -27px !important;
  }
}

@media screen and (max-width: 767px) {
  .consStep.active h3,
  .consStep h3 {
    font-size: 13px;
  }
  .consStep.active img {
    max-width: 50px;
  }
  .consStep .stepLine {
    width: 20px;
  }
  .consStep.active .stepLine {
    left: -23px;
  }
  .consStep .stepLine {
    left: -23px;
  }
  .imagUp-heading {
    width: auto;
    font-size: 18px;
  }
  .consStep .consTab {
    padding: 0.5rem;
    border-radius: 5px;
    min-width: 90px;
  }
  .consStep .consTab .consTabImg {
    width: 40px;
    height: 40px;
    padding: 0.5rem;
  }
  .consStep .consTab .consTabImg img {
    max-width: 25px;
  }
  .consStep .consTab p {
    font-size: 12px;
  }
  .unitHead {
    margin-bottom: 1rem;
  }
  .equipmentSec {
    margin-top: 2rem !important;
  }
  .unitInfo-heading {
    font-size: 23px;
    text-align: left;
  }
  .resetPart {
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }
  .main-button {
    font-size: 16px;
    padding: 8px 20px;
  }
}
</style>
