<template>
  <div class="container pt-4">
    <div v-if="isShowQuote">
      <AlertNote v-if="checkNoteAlert()"></AlertNote>
      <!-- <div class="btn-group" v-if="!equipmentInfo">
            <button @click=backButtonClicked(action) class="btn main-button m-2 btn-sm">Back</button>
        </div> -->
      <div class="breadcrumbNavigation">
        <div @click="backPressed()">
          <ul class="breadcrumb custom-breadcrumb mb-0">
            <li
              v-for="(item, index) in crumbs"
              :key="index"
              class="breadcrumb-item"
            >
              <a class="fw-medium text-decoration-none" @click="crumbClicked(item, index)">{{
                item
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <Notes v-if="notes"></Notes>
      <TabButtons></TabButtons>
      <ImageGallery :isBefore="1" v-if="gallery"></ImageGallery>
      <div v-if="quoteDetail!=null && quoteDetail.trade.title == 'Hvac'">
        <HvacEquipmentInfo v-if="equipmentInfo"></HvacEquipmentInfo>
      </div>
      <div v-else>
        <EquipmentInfo v-if="equipmentInfo"></EquipmentInfo>
      </div>
      <QuoteItems v-if="quoteItems"></QuoteItems>
      <QuoteSummary v-if="quoteSummary"></QuoteSummary>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Notes from "@/components/notes/Notes.vue";
import EquipmentInfo from "@/components/quote/EquipmentInfo.vue";
import HvacEquipmentInfo from "@/components/quote/HvacEquipmentInfo.vue";
import QuoteItems from "@/components/quote/QuoteItems.vue";
import QuoteSummary from "@/components/quote/QuoteSummary.vue";
import TabButtons from "@/components/tab-buttons/TabButtons.vue";
import ImageGallery from "@/components/image_gallery/ImageGallery.vue";
import AlertNote from "@/components/alert_note/AlertNote.vue";

export default {
  components: {
    Notes,
    EquipmentInfo,
    HvacEquipmentInfo,
    QuoteItems,
    QuoteSummary,
    TabButtons,
    ImageGallery,
    AlertNote
  },

  data() {
    return {
      crumbs: ["Quote Details", "Equipment Info"],
      isShowQuote: false,
      notes: true,
      equipmentInfo: true,
      gallery: true,
      quoteItems: false,
      quoteSummary: false,
    };
  },
  computed: {
    ...mapGetters(["quoteDetail", "componentState"]),
  },

  watch: {
    quoteDetail: function (data) {
      if (data.status.technician.status == 0) {
        this.isShowQuote = true;
      } else {
        this.$router.push({ path: `/quote/detail/${this.quoteDetail.id}` });
      }
    },

    componentState: function (data) {
      localStorage.setItem(
        this.$route.params.id + "-quote",
        JSON.stringify(data)
      );
      this.crumbs = [];
      this.crumbs.push("Quote Details");
      this.crumbs.push("Equipment Info");
      this.resetCommponent();
      switch (data) {
        case "Notes":
          this.notes = true;
          this.equipmentInfo = true;
          this.gallery = true;
          break;
        case "QuoteItems":
          this.notes = true;
          this.quoteItems = true;

          this.crumbs.push("Quote Items");
          break;
        case "QuoteSummary":
          this.notes = true;
          this.quoteSummary = true;

          this.crumbs.push("Quote Items");
          this.crumbs.push("Summary");
          break;
      }
    },
  },

  mounted() {
    this.getQuoteDetail({
      manual_quote_id: this.$route.params.id,
    });
    var data =
      JSON.parse(localStorage.getItem(this.$route.params.id + "-quote")) ??
      "Notes";
    this.setCommponent(data);
  },

  methods: {
    ...mapActions(["setCommponent", "getQuoteDetail"]),
    resetCommponent() {
      this.gallery = false;
      this.notes = false;
      this.equipmentInfo = false;
      this.quoteItems = false;
      this.quoteSummary = false;
    },

    checkNoteAlert() {
      if (this.quoteDetail &&
        this.quoteDetail.partner_info
        && this.quoteDetail.partner_info.show_replacement_opportunity_badge == '1'
        && this.quoteDetail.partner_info.status == '1'
        && this.quoteDetail.partner_info.company_name != 'Vivint'
        && this.quoteDetail.partner_info.replacement_opportunity_number.length > 0) {
        return true
      } else {
        return false
      }
    },

    backButtonClicked() {
      switch (this.componentState) {
        case "QuoteItems":
          this.setCommponent("Notes");
          break;
        case "QuoteSummary":
          this.setCommponent("QuoteItems");
          break;
      }
    },

    crumbClicked(item) {
      if (item == "Equipment Info") {
        this.setCommponent("Notes");
      } else if (item == "Quote Items") {
        this.setCommponent("QuoteItems");
      } else if (item == "Quote Details") {
        this.$router.push({ path: `/quote/detail/${this.quoteDetail.id}` });
      }
    },
  },
};
</script>

<style>
.custom-breadcrumb {
  background-color: #fff;
}

.custom-breadcrumb a {
  margin: 5px 0 0;
  font-weight: 600;
}

.custom-breadcrumb .breadcrumb-item a {
  cursor: pointer;
  color: #ff5b01;
  text-decoration: underline;
}

.custom-breadcrumb .breadcrumb-item:last-child a {
  color: #7e7e7e;
  cursor: default;
  text-decoration: none;
}

.breadcrumbNavigation {
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
}
</style>
