<template>
  <div class="container text-center">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for might be in another page.</p>
    <div class="text-center mt-3">
      <button @click="back" class="btn main-button">Back</button>
    </div>
  </div>
</template>
  
<script>


export default {
  methods: {

    back() {
      this.$router.go(-2)
    }
  },
  name: 'NotFound',
};
</script>
  
<style scoped>
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  text-align: center;
  padding: 50px;
  margin: 0;
}


h1 {
  color: #d9534f;
}

p {
  font-size: 18px;
}
</style>