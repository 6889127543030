<template>
  <main class="evaluationPreCheckPg">
    <div class="container">
      <div class="heading">
        <h4 class="mb-0 blackText fw-bold pt-4 mb-4">
          <b>Eval. Pre-Check </b> - Job ID <span class="orangeText">8448</span>
        </h4>
      </div>
      <div class="breadcrumbs border rounded p-2 mb-4">
        <ul class="d-flex list-inline mb-0">
          <li><a class="greyText">My Account</a></li>
          <li>
            <a class="greyText"
              >Service & Repair History</a
            >
          </li>
          <li>
            <a :href="'/job/detail/'+jobId" class="greyText">Job Detail</a>
          </li>
          <li class="blueText">Eval. Pre-Check - Job ID 8448</li>
        </ul>
      </div>
      <div class="preChecksec">
        <h5 class="blueText mt-4 mb-3">Split System</h5>
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">Has the equipment been maintained properly?</h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="equipmentYes"
                    type="radio"
                    class="form__radio-input"
                    name="equipment"
                    checked
                  />
                  <label for="equipmentYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="equipmentNo"
                    type="radio"
                    class="form__radio-input"
                    name="equipment"
                  />
                  <label for="equipmentNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">Has the equipment been installed correctly?</h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="correctlyYes"
                    type="radio"
                    class="form__radio-input"
                    name="correctly"
                    checked
                  />
                  <label for="correctlyYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="correctlyNo"
                    type="radio"
                    class="form__radio-input"
                    name="correctly"
                  />
                  <label for="correctlyNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">Are there any unusual noises or vibrations?</h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="noiseYes"
                    type="radio"
                    class="form__radio-input"
                    name="noise"
                    checked
                  />
                  <label for="noiseYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="noiseNo"
                    type="radio"
                    class="form__radio-input"
                    name="noise"
                  />
                  <label for="noiseNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">
                Does the ductwork appear to be in good condition?
              </h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="conditionYes"
                    type="radio"
                    class="form__radio-input"
                    name="condition"
                    checked
                  />
                  <label for="conditionYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="conditionNo"
                    type="radio"
                    class="form__radio-input"
                    name="condition"
                  />
                  <label for="conditionNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">
                Is the unit operating in a harsh environment?
              </h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="operatingYes"
                    type="radio"
                    class="form__radio-input"
                    name="operating"
                    checked
                  />
                  <label for="operatingYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="operatingNo"
                    type="radio"
                    class="form__radio-input"
                    name="operating"
                  />
                  <label for="operatingNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">
                Will the system likely need to be replaced in the next 12
                months?
              </h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="replacedYes"
                    type="radio"
                    class="form__radio-input"
                    name="replaced"
                    checked
                  />
                  <label for="replacedYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="replacedNo"
                    type="radio"
                    class="form__radio-input"
                    name="replaced"
                  />
                  <label for="replacedNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">
                Is the system eligible for an extended warranty?
              </h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="eligibleYes"
                    type="radio"
                    class="form__radio-input"
                    name="eligible"
                    checked
                  />
                  <label for="eligibleYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="eligibleNo"
                    type="radio"
                    class="form__radio-input"
                    name="eligible"
                  />
                  <label for="eligibleNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div
              class="form-group d-flex justify-content-between align-items-center mb-3 lightGreyBg p-3 rounded"
            >
              <h6 class="mb-0">Is UV light installed?</h6>
              <div class="radioCheck d-flex">
                <div class="form__radio-group me-3">
                  <input
                    id="uvYes"
                    type="radio"
                    class="form__radio-input"
                    name="uv"
                    checked
                  />
                  <label for="uvYes" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    <span class="form__radio-label-text">Yes</span>
                  </label>
                </div>
                <div class="form__radio-group">
                  <input
                    id="uvNo"
                    type="radio"
                    class="form__radio-input"
                    name="uv"
                  />
                  <label for="uvNo" class="form__radio-label">
                    <span class="form__radio-button"></span>
                    No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="from-group">
              <label class="orangeText" for="notes">Notes</label>
              <textarea
                name="notes"
                rows="5"
                placeholder="Enter notes here"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <button class="btn buttonPrimary mt-5 mx-auto d-table rounded">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {
  },
};
</script>
