<template>
  <div class="p-2">
    <!-- <div class="modal-header">
      <h5 class="modal-title">Scan Barcode</h5>
      <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
    </div> -->
    <div class="barcodeHeader d-flex justify-content-between align-items-center primaryBg mb-3 p-2 rounded">
      <h5 class="mb-0 whiteText fw-bold ">Scan Barcode</h5>
      <div class="closebutton d-flex justify-content-end">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        ></button>
      </div>
    </div>
    <div class="modal-body">
      <div ref="barcodeScanner" id="barcode-scanner" class="video"></div>
    </div>
  </div>
</template>

<script>
import Quagga from "quagga";

export default {
  components: {},
  props: ["showModal"],

  watch: {
    showModal(newValue) {
      if (newValue) {
        this.initBarcodeScanner();
      } else {
        this.stopBarcodeScanner();
      }
    },
  },

  data() {
    return {
      // 'qr' or 'barcode'
      result: null,
    };
  },
  mounted() {},
  methods: {
    onDecode(content) {
      this.result = content;
      this.$emit("scan-result", this.result);
      this.stopBarcodeScanner();
    },
    onInit(promise) {
      promise.catch((error) => {
        console.error(error);
        alert("Failed to initialize QR code scanner.");
      });
    },
    initBarcodeScanner() {
      Quagga.init(
        {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: this.$refs.barcodeScanner,
            constraints: {
              facingMode: "environment", // or 'user' for front camera
            },
          },
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
              "i2of5_reader",
              "2of5_reader",
              "code_93_reader",
            ],
          },
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setTimeout(() => {
            Quagga.start();
          }, 100);
        }
      );

      Quagga.onDetected(this.onBarcodeDetected);
    },
    onBarcodeDetected(result) {
      this.result = result.codeResult.code;
      setTimeout(() => {
        this.$emit("scan-result", this.result);
        this.closeModal();
      }, 100);
      
      // Quagga.stop();
    },
    stopBarcodeScanner() {
      Quagga.offDetected(this.onBarcodeDetected);
      Quagga.stop();
    },

    closeModal() {
      this.stopBarcodeScanner();
      this.$emit("close");
    },
  },

  beforeDestroy() {
    this.stopBarcodeScanner();
  },
};
</script>

<style scoped>
#barcode-scanner {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
#barcode-scanner[data-v-1dff094c] video {
  width: 100% !important;
}
/* .closebutton{
  padding: 0.2rem;
  border: 2px solid #000;
  border-radius: 50%;
  filter: invert(1);
}
.btn-close{
  font-size: 12px;
  opacity: 1;
} */
</style>
